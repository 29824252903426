import axios from "axios";
import { server } from "../utils/helper";
import authHeader from "./auth-header";

const API_URL = server.baseURL + "project/";
class ProjectService {
  async fetchProjects() {
    return await axios.get(API_URL + "list").then((response) => {
      console.log(response);
      return response.data;
    });
  }
  async fetchProjectsForHome() {
    return await axios.get(API_URL + "list-home").then((response) => {
      return response.data;
    });
  }
  async deleteProject(id) {
    return await axios
      .delete(`${API_URL}delete?projectID=${id}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  async createProject(postData) {
    return axios
      .post(API_URL + "create", postData, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  async getProject(id) {
    return axios.get(`${API_URL}view/${id}`).then((response) => {
      return response.data;
    });
  }
  async editProject(id, postData) {
    console.log(postData);
    axios
      .put(`${API_URL}edit?projectID=${id}`, postData, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}
export default new ProjectService();
