<template>
  <div>
    <div class="text-center">
      <router-link to="/project/create" v-if="currentUser" class="btn btn-primary">Create New</router-link>

      <div v-if="data.projects.length === 0">
        <h2>No project found at the moment</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-5" v-for="(project, index) in data.projects" :key="project._id">
        <div class="card mb-4 mt-5">
          <div class="row">
            <div class="col-md-2"></div>
            <odd-project v-if="index % 2 == 0" :project="project" @deleteProject="getProjectsData" />
            <even-project v-if="index % 2 == 1" :project="project" @deleteProject="getProjectsData" />
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import projectService from "../services/project.service";
import OddProject from "../components/project/OddProject.vue";
import EvenProject from "../components/project/EvenProject.vue";
import store from "@/store";
export default defineComponent({
  name: "Project",
  components: { OddProject, EvenProject },
  setup() {
    const data = reactive({
      projects: [],
    });
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    onMounted(async () => {
      getProjectsData();
      console.log(data.projects);
    });
    const getProjectsData = async () => {
      data.projects = await projectService.fetchProjects();
    };
    return {
      data,
      currentUser,
      getProjectsData,
    };
  },
});
</script>

<style scoped>
.wrapper {
  padding-top: 3rem !important;
}

.card-body {
  padding: 4rem;
}
</style>
