<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>{{ currentUser.name }}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Token:</strong>
      {{ currentUser.token.substring(0, 20) }} ...
      {{ currentUser.token.substr(currentUser.token.length - 20) }}
    </p>
    <p>
      <strong>Id:</strong>
      {{ currentUser.id }}
    </p>
    <p>
      <strong>Email:</strong>
      {{ currentUser.email }}
    </p>
    <strong>Authorities:</strong>
  </div>
</template>
<script>
import { defineComponent, computed, onMounted } from "vue";
import router from "../router";
import store from "../store";

export default defineComponent({
  name: "Profile",
  setup() {
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    onMounted(() => {
      if (!currentUser.value) router.push("/tipsy-admin");
    });
    return {
      currentUser,
    };
  },
});
</script>
