<template>
  <div class="container-fluid" style="margin-top: 100px">
    <div>
      <div class="rounded d-flex justify-content-center">
        <div class="col-md-4 col-sm-12 shadow-lg p-5 bg-light">
          <div class="text-center">
            <h3 class="text-primary">Sign In</h3>
          </div>
          <form @submit.prevent="login">
            <div class="form-group pt-3">
              <label for="exampleInputEmail1">Email address</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
              <small class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group pt-3">
              <label for="exampleInputPassword1">Password</label>
              <input v-model="password" type="password" class="form-control" placeholder="Password" />
            </div>
            <div class="form-group form-check pt-3">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>
            <div class="pt-3">
              <button type="submit" class="btn btn-primary">
                <span
                  v-if="loading"
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="loading" class="pt-5">&nbsp;Loading...</span>
                <span v-if="!loading">Login</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import store from "../store";
import router from "../router";

export default defineComponent({
  setup() {
    let loading = ref(false);
    let message = ref("");
    const email = ref("");
    const password = ref("");
    const login = () => {
      loading = true;

      store
        .dispatch("auth/login", {
          email: email.value,
          password: password.value,
        })
        .then(
          () => {
            router.push("/profile");
          },
          (error) => {
            loading = false;
            message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    };
    return { email, password, login, loading, message };
  },
});
</script>

<style scoped>
#wrapper {
  margin-top: 122px;
  /* background-image: url("~@/assets/images/sample.png"); */
  height: 448px;
  background-position: center;
  background-repeat: no-repeat;
}

.detail {
  font-size: 1.4rem !important;
  padding-top: 12px;
  line-height: 27px;
  font-weight: 300;
}

.pic {
  margin-left: -60px;
}
</style>
