import axios from "axios";
import { server } from "../utils/helper";

const API_URL = server.baseURL + "auth/";
class AuthService {
  login(user) {
    const payload = {
      email: user.email,
      password: user.password,
    };
    return axios.post(API_URL + "login", payload).then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        console.log("setting");
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(user, "-----getting");
      }
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(user) {
    return axios.post(API_URL + "signup", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }
}
export default new AuthService();
