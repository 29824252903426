import axios from "axios";
import { server } from "../utils/helper";
// import authHeader from "./auth-header";

const API_URL = server.baseURL + "image/";
class ImageService {
  async uploadFile(file, onUploadProgress) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return await axios.post(API_URL + "upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
  async fetchImages() {
    return await axios.get(API_URL + "list").then((response) => {
      return response.data;
    });
  }
}

export default new ImageService();
