import { createWebHistory, createRouter } from "vue-router";

import HomeComponent from "@/views/Home";
import AboutComponent from "@/views/About";
import BlogComponent from "@/views/Blog";
import OthersComponent from "@/views/Others";
import LoginComponent from "@/views/Login";
import EditComponent from "@/components/post/Edit";
import CreatePostComponent from "@/components/post/Create";
import PostComponent from "@/components/post/Post";
import ProfileComponent from "@/views/Profile";
import ProjectComponent from "@/views/Project";
import CreateProjectComponent from "@/components/project/Create";
import ProjectViewComponent from "@/components/project/View";
import ProjectEditComponent from "@/components/project/Edit";
import PageNotFound from "@/views/PageNotFound";
import RubaaruuComponent from "@/views/Rubaaruu";
import UploadImageComponent from "@/views/UploadImage";
import Photography from "@/views/Photography";
import Illustration from "@/views/Illustration";
import Material from "@/views/Material";

const routes = [
  { path: "/", name: "root", component: HomeComponent },
  { path: "/about", name: "about", component: AboutComponent },
  { path: "/others", name: "others", component: OthersComponent },
  { path: "/photography", name: "photography", component: Photography },
  { path: "/illustration", name: "illustration", component: Illustration },
  { path: "/material", name: "material", component: Material },
  { path: "/home", redirect: { name: "root" } },
  { path: "/tipsy-admin", name: "login", component: LoginComponent },
  { path: "/blog", name: "blog", component: BlogComponent },
  {
    path: "/ru-baa-ruu",
    name: "ru-baa-ru",
    component: RubaaruuComponent,
    meta: {
      noheader: 1,
      nofooter: 1,
    },
  },
  {
    path: "/blog/create",
    name: "CreatePost",
    component: CreatePostComponent,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem("user");
      if (!loggedIn) {
        next(false);
      } else {
        next();
      }
    },
  },
  {
    path: "/blog/edit/:id",
    name: "PostEdit",
    component: EditComponent,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem("user");
      if (!loggedIn) {
        next(false);
      } else {
        next();
      }
    },
  },
  { path: "/blog/post/:id", name: "PostView", component: PostComponent },
  { path: "/projects", name: "projects", component: ProjectComponent },
  {
    path: "/project/view/:id",
    name: "ProjectView",
    component: ProjectViewComponent,
  },
  {
    path: "/project/create",
    name: "New Project",
    component: CreateProjectComponent,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem("user");
      if (!loggedIn) {
        next(false);
      } else {
        next();
      }
    },
  },
  {
    path: "/project/edit/:id",
    name: "ProjectEdit",
    component: ProjectEditComponent,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem("user");
      if (!loggedIn) {
        next(false);
      } else {
        next();
      }
    },
  },
  { path: "/profile", name: "profile", component: ProfileComponent },
  {
    path: "/image-manager",
    name: "image-manager",
    component: UploadImageComponent,
  },
  { path: "/:pathMatch(.*)*", name: "Not Found", component: PageNotFound },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
