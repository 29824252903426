<template>
  <div id="app">
    <Navbar v-if="$route.meta.noheader !== 1" />
    <router-view />
    <Footer v-if="$route.meta.nofooter !== 1" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";

export default defineComponent({
  name: "App",
  components: {
    Navbar,
    Footer,
  },
});
</script>

<style>
/* #app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */
#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
