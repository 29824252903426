<template>
  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col" v-for="(image, index) in data.images" :key="index" v-on:click="selectImage(image)">
      <div class="card">
        <img :src="`${server.baseURL}public/uploads/100X100/${image.filename}`" :alt="image.filename"
          class="img-thumbnail" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import imageService from "@/services/image.service";
import { server } from "@/utils/helper";
import store from "@/store";
export default defineComponent({
  name: "ImageViewer",
  data() {
    return { server };
  },
  setup(props, { emit }) {
    const data = reactive({
      images: [],
    });
    const currentUser = computed(() => {
      return store.state.auth.user;
    });

    const selectImage = (image) => {
      emit("imageSelected", image);
      console.log(image);
    };
    onMounted(async () => {
      data.images = await imageService.fetchImages();
      console.log(data.images);
    });
    return {
      data,
      currentUser,
      selectImage,
    };
  },
});
</script>

<style>
.img-thumbnail {
  max-height: 100px !important;
  max-width: 100px !important;
}

.card {
  border: 0px !important;
  cursor: pointer;
}
</style>
