<template>
  <div class="dropzone">
    <input
      type="file"
      class="dropzone-file"
      id="dropzone-file"
      multiple
      accept="image/*"
      ref="dropzoneFile"
      @change="handleFiles"
    />
    <div class="dropzone-wrapper" @dragenter.prevent @dragover.prevent="handleFiles">
      <label for="dropzone-file" class="dropzone-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="dropzone-label-icon"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"
          />
        </svg>
        <div class="dropzone-label-text">
          <p>
            <strong>Drag and drop files to upload</strong>
          </p>
          <p>
            <small class="secondary-text">Your files will be added automatically</small>
          </p>
          <a class="btn btn-primary">or select files</a>
        </div>
      </label>
    </div>
  </div>
  <div class="list-group">
    <a
      href="#"
      class="list-group-item list-group-item-action"
      v-for="(file, index) in files"
      :key="index"
    >
      <div class="row">
        <div class="col-4">
          <figure v-if="getFileType(file.type) == 'image'" class="figure">
            <img
              :src="getFileImage(file)"
              @load="loadFileImage(file)"
              :alt="file.name"
              class="img-thumbnail"
              height="100px"
            />
          </figure>
        </div>
        <div class="col-8">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1">{{ getFileName(file.name) }}</h6>
            <small>{{ file.type }}</small>
          </div>
          <p class="mb-1 mt-2">Size: {{ getFileSize(file.size) }}</p>
          <small>Last modified: {{ getFileDate(file.lastModified) }}</small>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import {
  getFileSize,
  getFileName,
  getFileType,
  getFileDate,
  getFileImage,
  loadFileImage,
} from "@/utils/image-lib";

export default {
  name: "Dropzone",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      files: [],
      getFileSize,
      getFileName,
      getFileType,
      getFileDate,
      getFileImage,
      loadFileImage,
    };
  },
  methods: {
    handleFiles(e) {
      const inputValue =
        e.target.files || e.dataTransfer.files || this.$refs.dropzoneFile.files;
      for (let i = 0; i < inputValue.length; i++) {
        const fileItem = inputValue[i];
        this.files.push(fileItem);
      }
      let uniqueObjArray = [
        ...new Map(this.files.map((item) => [item["name"], item])).values(),
      ];
      this.files = uniqueObjArray;
      this.$emit("update:modelValue", this.files);
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  border: 2px dotted;
  border-color: $primary;
  text-align: center;
  padding: 2rem;
}
.dropzone-file {
  display: none;
}
.dropzone-label {
  width: 100%;
}
.dropzone-label-icon {
  width: 100px;
  height: 100px;
  color: $light-grey;
}

.img-thumbnail {
  max-height: 100px !important;
}
</style>