<template>
  <div class="col-md-4 d-flex flex-row align-items-center justify-content-center">
    <div class="card-body">
      <h5 class="card-title semibold">{{ project.title }}</h5>
      <p class="card-text regular">{{ project.description }}</p>
      <small class="text-muted semibold tags">{{ project.tags }}</small>
      <div class="d-flex justify-content-between align-items-center">
        <div class="btn-group" style="margin-bottom: 20px; margin-top: 20px">

          <router-link :to="{ name: 'ProjectView', params: { id: project._id } }"
            class="btn btn-outline-secondary">View Case Study</router-link>
          <router-link :to="{ name: 'ProjectEdit', params: { id: project._id } }"
            class="btn btn-outline-secondary" v-if="currentUser">Edit Project</router-link>
          <button class="btn btn-outline-secondary" v-on:click="deleteProject(project._id)" v-if="currentUser">
            Delete Project
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <img :src="project.bigImageUrl" class="project-image w-100" />
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import store from "@/store";
import projectService from "@/services/project.service";

export default defineComponent({
  name: "EvenProject",
  props: ["project"],
  setup(props, { emit }) {
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    const deleteProject = async (id) => {
      await projectService.deleteProject(id);
      emit("deleteProject");
    };
    return {
      currentUser,
      deleteProject,
    };
  },
});
</script>
<style scoped>
.project-image {
  max-width: 500px;
}

.card-title {
  font-size: 2.125rem;
}

.card-text {
  font-size: 1.125rem;
  line-height: 1.375;
}

.tags {
  color: #c4c4c4 !important;
}
</style>
