<template>
  <div>
    <h4 class="text-center mt-20">
      <small>
        <button class="btn btn-success" v-on:click="navigate()">View All Posts</button>
      </small>
    </h4>
    <div class="col-md-12 form-wrapper">
      <h2>Edit Post</h2>
      <form id="edit-post-form" @submit.prevent="editPost">
        <div class="form-group col-md-12">
          <label for="title">Title</label>
          <input type="text" id="title" v-model="data.post.title" name="title" class="form-control"
            placeholder="Enter title" />
        </div>
        <div class="form-group col-md-12">
          <label for="description">Description</label>
          <input type="text" id="description" v-model="data.post.description" name="description" class="form-control"
            placeholder="Enter Description" />
        </div>
        <div class="form-group col-md-12">
          <label for="body">Write Content</label>
          <editor v-model="data.post.body" api-key="iyxpc1oy43cg2fxf6dha0hm3mt6lj4y0h2zduriecmw0d702" :init="{
            height: 500,
            images_upload_url: `${server.baseURL}image/tiny-upload`,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            menubar: 'file edit view insert format tools table help',
            fontsize_formats:
              '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 38pt 40pt 42pt 44pt 46pt 48pt 50pt 52pt 54pt 56pt 58pt 60pt 62pt 64pt 66pt 68pt 70pt 72pt 96pt',
            font_formats:
              'Open Sans=open sans; Roboto=roboto; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; Typewriter=typewriter',
            toolbar:
              'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          }" />
        </div>
        <div class="form-group col-md-12 my-3">
          <label>Small Image</label>
          <a class="btn btn-outline-primary btn-sm m-2" @click="showModal('small')">Select Existing Image</a>
          <a class="btn btn-outline-primary btn-sm m-2" data-bs-toggle="modal" data-bs-target="#uploadModal">Upload
            new</a>
          <input type="hidden" v-model="smallImage" class="form-control" placeholder="Enter url" />
        </div>
        <img :src="data.post.imageUrl" class="img-thumbnail" />
        <div class="form-group col-md-12">
          <label for="title">Tags</label>
          <input type="text" id="tags" v-model="data.post.tags" name="tags" class="form-control"
            placeholder="Enter tags" />
        </div>
        <div class="form-group col-md-4 pull-right">
          <button class="btn btn-success" type="submit">Edit Post</button>
        </div>
      </form>
    </div>
  </div>
  <div class="modal fade" id="uploadModal" ref="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <upload-image />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="imageViewerModal" ref="imageViewerModal" tabindex="-1"
    aria-labelledby="imageViewerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="imageViewerModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <image-viewer @imageSelected="imageSelected" v-if="isSmall || isBig" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Editor from "@tinymce/tinymce-vue";
import { useRoute } from "vue-router";
import blogService from "../../services/blog.service";
import store from "@/store";
import router from "@/router";
import UploadImage from "../../components/common/UploadImage.vue";
import ImageViewer from "../common/media/ImageViewer.vue";
import { Modal } from "bootstrap";
import { server } from "@/utils/helper";

export default defineComponent({
  name: "Edit Blog",
  components: {
    editor: Editor,
    UploadImage,
    ImageViewer,
  },
  setup() {
    const route = useRoute();
    const data = reactive({
      id: 0,
      post: {},
    });
    const isSmall = ref(false);
    const isBig = ref(false);
    const smallImageThumbUrl = ref("");
    const bigImageThumbUrl = ref("");
    const bigImageUrl = ref("");

    let myModal = null;
    const showModal = (type) => {
      console.log(type, "----");
      if (type === "small") isSmall.value = true;
      if (type === "big") isBig.value = true;
      myModal = new Modal(document.getElementById("imageViewerModal"), {});
      myModal.show();
    };

    const hideModal = () => {
      console.log("hide");
      isSmall.value = false;
      isBig.value = false;
      myModal.hide();
    };
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    const editPost = async () => {
      let postData = {
        title: data.post.title,
        description: data.post.description,
        body: data.post.body,
        imageUrl: data.post.imageUrl,
        tags: data.post.tags,
      };
      await blogService.editPost(data.id, postData);
      router.push({ name: "blog" });
    };
    onMounted(async () => {
      data.id = route.params.id;
      data.post = await blogService.getPost(route.params.id);
    });
    const imageSelected = (img) => {
      if (isSmall.value) {
        smallImageThumbUrl.value =
          server.baseURL + "public/uploads/100X100/" + img.filename;
        data.post.imageUrl =
          server.baseURL + "public/uploads/" + img.filename;
      }
      if (isBig.value) {
        bigImageThumbUrl.value =
          server.baseURL + "public/uploads/100X100/" + img.filename;
        bigImageUrl.value = server.baseURL + "public/uploads/" + img.filename;
      }
      console.log(data, "here");
      hideModal();
    };
    return {
      data,
      currentUser,
      editPost,
      showModal,
      hideModal,
      isBig,
      isSmall,
      imageSelected,
      server,
    };
  },
});
</script>
