<template>
  <div class="row hello-top-container">
    <div class="col-12 col-lg-4 col-xl-4 text-center">
      <router-link to="/photography">
        <img
          src="https://ik.imagekit.io/shhlxzwhdeyt/photography_7RRP8IdaS.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1653032492072"
          class="m-auto" />
        <span>Photography</span>
      </router-link>
    </div>
    <div class="col-12 col-lg-4 col-xl-4 text-center">
      <router-link to="/illustration">
        <img
          src="https://ik.imagekit.io/shhlxzwhdeyt/illustration_mgnpDwEo-.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1653032492072"
          class="m-auto" />
        <span>Illustration</span>
      </router-link>
    </div>
    <div class="col-12 col-lg-4 col-xl-4 text-center">
      <router-link to="/material">
        <img
          src="https://ik.imagekit.io/shhlxzwhdeyt/material_PiKpOdMZe.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1653032492033"
          class="m-auto" />
        <span>Material Exploration</span>
      </router-link>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({});
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  max-width: 446px;
}

img:hover {
  width: 110%;
  max-width: 446px;
}

span {
  font-size: 1.5rem;
}

a {
  font-size: 1.5rem;
  color: #363636 !important;
  text-decoration: none;
}
</style>
