<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 style="margin-top: 30px"></h4>
      <h1 class="page-heading heading">Illustration</h1>
      <h4 class="tagline light">links</h4>
    </div>
  </div>
  <div class="photo-row" style="margin-top: 50px">
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index - 1].src"
        :title="images[index - 1].title" :desc="images[index - 1].desc" :series="images[index - 1].series"
        v-for="index in 7" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 7 - 1].src"
        :title="images[index + 7 - 1].title" :desc="images[index + 7 - 1].desc" :series="images[index + 7 - 1].series"
        v-for="index in 7" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 14 - 1].src"
        :title="images[index + 14 - 1].title" :desc="images[index + 14 - 1].desc"
        :series="images[index + 14 - 1].series" v-for="index in 7" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 21 - 1].src"
        :title="images[index + 21 - 1].title" :desc="images[index + 21 - 1].desc"
        :series="images[index + 21 - 1].series" v-for="index in 7" :key="index" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
// import ExpandableImage from "vue-expandable-image";
import ExpandableImage from "../components/common/ExpandableImage.vue";
export default defineComponent({
  components: { ExpandableImage },
  setup() {
    const images = ref([]);
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.1_UlNh80_GS?ik-sdk-version=javascript-1.4.3&updatedAt=1652731944628",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.2_2Z7Y8Yehc?ik-sdk-version=javascript-1.4.3&updatedAt=1652731945078",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.3_7BmXboCxg?ik-sdk-version=javascript-1.4.3&updatedAt=1652731945105",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.4_pqgufgj7k?ik-sdk-version=javascript-1.4.3&updatedAt=1652731945277",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.5_kpU8yoe_X?ik-sdk-version=javascript-1.4.3&updatedAt=1652731945792",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.6_8btol02O-?ik-sdk-version=javascript-1.4.3&updatedAt=1652731974089",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R1.7_ai6AUOdT8?ik-sdk-version=javascript-1.4.3&updatedAt=1652731961850",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.1_x09I0YAQH?ik-sdk-version=javascript-1.4.3&updatedAt=1652732381646",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.2_rEUEZr5jQ?ik-sdk-version=javascript-1.4.3&updatedAt=1652732381878",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.3_AuwMpPu4l?ik-sdk-version=javascript-1.4.3&updatedAt=1652732382704",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.4_Ckl25I8Za?ik-sdk-version=javascript-1.4.3&updatedAt=1652732383038",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.5_nqVN3j81N?ik-sdk-version=javascript-1.4.3&updatedAt=1652732383402",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.6_OazOOmmG7?ik-sdk-version=javascript-1.4.3&updatedAt=1652732383519",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R2.7_wGp4NNQc3?ik-sdk-version=javascript-1.4.3&updatedAt=1652732384070",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.1_sohXsJWMa?ik-sdk-version=javascript-1.4.3&updatedAt=1652732713264",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.2_jZgsqEe3B?ik-sdk-version=javascript-1.4.3&updatedAt=1652732713291",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.3_SYPk_EncO?ik-sdk-version=javascript-1.4.3&updatedAt=1652732713911",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.4_oykHnlNyk?ik-sdk-version=javascript-1.4.3&updatedAt=1652732714324",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.5_0EKXw7g1m?ik-sdk-version=javascript-1.4.3&updatedAt=1652732715149",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.6_rb9iWy3qY?ik-sdk-version=javascript-1.4.3&updatedAt=1652732715163",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R3.7_AfGLGpzjg?ik-sdk-version=javascript-1.4.3&updatedAt=1652732715400",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.1_u-bkKHYRR?ik-sdk-version=javascript-1.4.3&updatedAt=1652733076105",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.2__FR56L8DA?ik-sdk-version=javascript-1.4.3&updatedAt=1652733076209",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.3_WztTPMXOW?ik-sdk-version=javascript-1.4.3&updatedAt=1652733076771",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.4_cDcJ2cwx2?ik-sdk-version=javascript-1.4.3&updatedAt=1652733077257",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.5_3ZzziLtvi?ik-sdk-version=javascript-1.4.3&updatedAt=1652733077497",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.6_bL5j74D8r?ik-sdk-version=javascript-1.4.3&updatedAt=1652733077791",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/illustration/R4.7_6slZT7eqF?ik-sdk-version=javascript-1.4.3&updatedAt=1652733078790",
    });
    return {
      images,
    };
  },
});
</script>

<style lang="scss" scoped>
.photo-row {
  display: flex;
  flex-wrap: wrap;
}

.photo-col {
  flex: 25%;
  max-width: 25%;
}

.image {
  vertical-align: middle;
  width: 100%;
  padding: 10px;
}
</style>
