<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 style="margin-top: 30px"></h4>
      <h1 class="page-heading heading">{{ data.post.title }}</h1>
      <div class="tagline regular">
        {{ dateTime(data.post.date_posted) }}
        <br />
        by mansi
      </div>
      <hr />
      <p v-html="data.post.body"></p>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import moment from "moment";
import { useRoute } from "vue-router";
import blogService from "../../services/blog.service";
import store from "@/store";
export default defineComponent({
  name: "Blog",
  setup() {
    const route = useRoute();
    const data = reactive({
      post: {},
    });
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    onMounted(async () => {
      data.post = await blogService.getPost(route.params.id);
      const imgTags = data.post.body.match(
        /<img [^>]*width="[^"]*"[^>]*>/gm
      );
      const tmp = document.createElement("div");
      if (null !== imgTags) {
        imgTags.map((x) => {
          tmp.innerHTML = x;
          const image = tmp.querySelector("img");
          const width = image.getAttribute("width");
          image.setAttribute("style", "max-width:" + width + "px");
          image.className = "responsive";
          console.log(x, tmp.innerHTML, "--");
          data.post.body = data.post.body.replace(x, tmp.innerHTML);
          // data.project.body = data.project.body.replaceAll(x, tmp.innerHTML);
        });
      }

    });
    const dateTime = (value) => {
      return moment(value).format("MMM DD, YYYY");
    };
    return {
      data,
      currentUser,
      dateTime,
    };
  },
});
</script>
<style scoped>
.tagline {
  font-size: 1.125rem;
  color: #aeaeae;
}
</style>
