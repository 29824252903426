<template>
  <div class="row hello-top-container">
    <div class="col-12 col-lg-5 col-xl-5">
      <div class="align-items-center" align="center">
        <img :src="meImg" @mouseover="meImg = mansiImg" class="w-100 me" />
      </div>
    </div>
    <div class="col-12 col-lg-7 col-xl-7 my-auto">
      <h2 class="hello heading">Hello!</h2>
      <div class="row">
        <div class="col-10 col-sm-12 gt-2">
          <p class="detail">
            This is Mansi. Currently, I am working as a Product Designer with a Bachelor's degree in Human-Centered Design from the Srishti Institute of Art, Design, and Technology. 
            <br />
            <br />
            I am a learning creative practitioner and a product designer in-making. With my analytical and collaborative approach, I visualize ideas from different perspectives and strive to create meaningful experiences.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-10 col-sm-12 gt-2 detail">
          <span
            ><a
              class="link typewriter-link"
              href="mailto:1303mansi@gmail.com"
              target="_blank"
              >Let's connect</a
            ></span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 quote mt-5">
          <div>“mai is umeed mai duba ki tu bacha legi,</div>
          <div>ab iske bad mera inteha tu kya legi”</div>
          <div>-Waseem Barelvi</div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-10 col-sm-12 gt-2 detail">
          <span>Here is my <a class="link typewriter-link"
              href="https://ik.imagekit.io/shhlxzwhdeyt/MansiSrivastava_resume__1__I5zBzHh1w.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1652477111155"
              target="_blank">resume</a></span>
        </div>
      </div> -->
    </div>
  </div>
  <div class="row hello-top-container">
    <div class="col-12 col-lg-4 col-xl-4"></div>
    <div
      class="col-12 col-lg-4 col-xl-4"
      style="padding-left: 40px; padding-right: 40px; align-items: center"
    >
      <div class="flex-container justify-content-center">
        <div class="flex1 custom-tooltip">
          <div class="custom-tooltiptext custom-tooltip-left">
            they make the<br />blurry visible
          </div>
          <div class="creativity-kit1">
            <img src="@/assets/images/specs.png" class="w-100" />
          </div>
        </div>
        <div class="flex2 custom-tooltip">
          <div class="custom-tooltiptext custom-tooltip-right">
            apple juice potion<br />of motivation
          </div>
          <div class="creativity-kit2">
            <img src="@/assets/images/juice.png" class="w-100" />
          </div>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex3 custom-tooltip">
          <div class="custom-tooltiptext custom-tooltip-left">
            the secret device of<br />concentration
          </div>
          <div class="creativity-kit3">
            <img src="@/assets/images/airpod.png" class="w-100" />
          </div>
        </div>
        <div class="flex4 custom-tooltip">
          <div class="custom-tooltiptext custom-tooltip-right">
            through which<br />the magic happens
          </div>
          <div class="creativity-kit4">
            <img src="@/assets/images/laptop.png" class="w-100" />
          </div>
        </div>
      </div>
      <span class="fs-5 fw-light pt-5">my creativity kit</span>
    </div>
    <div class="col-12 col-lg-4 col-xl-4"></div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import meImg from "@/assets/images/me.webp";
import mansiImg from "@/assets/images/mansi.webp";

export default defineComponent({
  name: "About",
  setup() {
    return {meImg, mansiImg};
  }
});
</script>

<style lang="scss" scoped>
.hello-top-container {
  padding-top: 7rem;
}

.detail {
  font-size: clamp(1.125rem, 1.39vw, 1.5rem) !important;
  line-height: 125%;
  font-weight: 300 !important;
}

img.me {
  max-width: 300px;
}

img.me:hover {
  max-width: 300px;
}

.creativity-kit1 {
  border-radius: 20px;
  max-width: 240px;
}

.creativity-kit2 {
  border-radius: 20px;
  max-width: 164px;
}

.creativity-kit3 {
  border-radius: 20px;
  max-width: 164px;
}

.creativity-kit4 {
  border-radius: 20px;
  max-width: 240px;
}

.creativity-kit1:hover {
  transform: scale(1.1);
  cursor: pointer;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  transform-origin: bottom right;
}

.creativity-kit2:hover {
  transform: scale(1.1);
  cursor: pointer;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  transform-origin: bottom left;
}

.creativity-kit3:hover {
  transform: scale(1.1);
  cursor: pointer;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  transform-origin: top right;
}

.creativity-kit4:hover {
  transform: scale(1.1);
  cursor: pointer;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  transform-origin: top left;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  color: #006080;
}

.custom-tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 150px;
  background-color: $white;
  color: $body-color;
  text-align: center;
  margin: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  font-style: italic;
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.custom-tooltip-left {
  top: 35%;
  bottom: auto;
  right: calc(100% + 60px);
  text-align: right;
}

.custom-tooltip-right {
  top: 35%;
  bottom: auto;
  left: calc(100% + 60px);
  text-align: left;
}

.flex-container {
  display: flex;
  margin-bottom: 20px;
  max-width: 427px;
}

.flex1 {
  flex: 241px;
  margin-right: 20px;
}

.flex2 {
  flex: 164px;
}

.flex3 {
  flex: 164px;
  margin-right: 20px;
}

.flex4 {
  flex: 241px;
}
</style>
