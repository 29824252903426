import axios from "axios";
import { server } from "../utils/helper";
import authHeader from "./auth-header";

const API_URL = server.baseURL + "blog/";
class BlogService {
  async fetchPosts() {
    return await axios.get(API_URL + "posts").then((response) => {
      return response.data;
    });
  }
  async deletePost(id) {
    return await axios
      .delete(`${API_URL}delete?postID=${id}`)
      .then((response) => {
        return response.data;
      });
  }
  async createPost(postData) {
    return axios
      .post(API_URL + "post", postData, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  async getPost(id) {
    return axios.get(`${API_URL}post/${id}`).then((response) => {
      return response.data;
    });
  }
  async editPost(id, postData) {
    axios
      .put(`${API_URL}edit?postID=${id}`, postData, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}
export default new BlogService();
