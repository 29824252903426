<template>
  <div ref="mainContainer" v-show="isMainVisible">
    <div class="head">
      <div class="head-image">
        <img :src="RuBaaRuuHead" class="" />
        <div class="head-text">ru-baa-ru</div>
        <div class="head-sub-text">
          a repository of lived experience <br />
          with history of artefacts
        </div>
        <div class="down-link-wrapper">
          <a @click="scrollToRepository">
            <img :src="DownArrow" class="" />
          </a>
        </div>
      </div>
    </div>
    <div ref="repository" class="repository-top">
      <div class="repository animate">
        <div class="repository-cover" v-show="!hideOther">
          <img :src="RepositoryCover" />
        </div>
        <img :src="TVHover" class="tv-hover" v-show="hideOther && tvHover" />
        <img
          :src="TVCover"
          @mouseover="mouseToggle(true, 'tv')"
          @mouseleave="mouseToggle(false, 'tv')"
          @click="scrollToTVRepository"
          class="tv-cover"
          v-show="!hideOther || tvHover"
        />
        <img
          :src="RadioHover"
          class="radio-hover animate"
          v-show="hideOther && radioHover"
        />
        <img
          :src="RadioCover"
          @mouseover="mouseToggle(true, 'radio')"
          @mouseleave="mouseToggle(false, 'radio')"
          @click="scrollToRadioRepository"
          v-show="!hideOther || radioHover"
          class="radio-cover"
        />
        <img
          :src="EmailHover"
          class="email-hover"
          v-show="hideOther && emailHover"
        />
        <img
          :src="EmailCover"
          class="email-cover"
          @mouseover="mouseToggle(true, 'email')"
          @mouseleave="mouseToggle(false, 'email')"
          @click="scrollToEmailRepository"
          v-show="!hideOther || emailHover"
        />
        <img
          :src="AlexaHover"
          class="alexa-hover"
          v-show="alexaHover && hideOther"
        />
        <img
          :src="AlexaCover"
          class="alexa-cover"
          @mouseover="mouseToggle(true, 'alexa')"
          @mouseleave="mouseToggle(false, 'alexa')"
          @click="scrollToAlexaRepository"
          v-show="!hideOther || alexaHover"
        />

        <img
          :src="LaptopHover"
          class="laptop-hover"
          v-show="laptopHover && hideOther"
        />
        <img
          :src="LaptopCover"
          class="laptop-cover"
          @mouseover="mouseToggle(true, 'laptop')"
          @mouseleave="mouseToggle(false, 'laptop')"
          @click="scrollToLaptopRepository"
          v-show="!hideOther || laptopHover"
        />
        <img
          :src="MobileHover"
          class="mobile-hover"
          v-show="mobileHover && hideOther"
        />
        <img
          :src="MobileCover"
          class="mobile-cover"
          @mouseover="mouseToggle(true, 'mobile')"
          @mouseleave="mouseToggle(false, 'mobile')"
          @click="scrollToMobileRepository"
          v-show="!hideOther || mobileHover"
        />
        <img
          :src="IpadHover"
          class="ipad-hover"
          v-show="ipadHover && hideOther"
        />
        <img
          :src="IpadCover"
          class="ipad-cover"
          @mouseover="mouseToggle(true, 'ipad')"
          @mouseleave="mouseToggle(false, 'ipad')"
          @click="scrollToIpadRepository"
          v-show="!hideOther || ipadHover"
        />
      </div>
    </div>
    <div class="repo-description">
      <div class="repo-description-head">
        ruu-ba-ruu :ररू-ब-रू : روبرُو : to know in the presence of
      </div>
      <div class="repo-description-body">
        Beyond the creation that prioritises the needs of humans are the
        numerous, extensive and untold histories of non-human artefacts and
        systems. What we refer to as 'future experiences'— are and will be the
        interactions where human and non-human agents engage to form experiences
        with their surrounding ecosystem. These experiences can help us to
        understand the inherited values associated with the existing artefacts
        and systems and connect constituents across an end-to-end engagement to
        create a functional space within this ecosystem.
        <br /><br />
        Human experiences add uniqueness and diversity, which adds identity and
        character to these formed artefacts and systems and impact their
        experience with constant changes with time. In an evolving society, need
        can not be the only dominator for people's choices, but with the option
        of more choices and experiences, we are around an ecosystem where
        accommodation to change overlaps with need, want, desire and compulsion.
        <br /><br />
        Systems have created a compulsion to adapt and accommodate a form of
        technology that does not always reciprocate to their values and
        understandings. Today there are forms of inventions that can fulfil the
        need for a better experience, but without knowing or being aware of such
        a system's functionality, there is a sense of compulsion. For a better
        experience, there is a compulsion to change to adapt. In such a system,
        how do people reciprocate to the existing technology? What are their
        experience related to it? What is the role of design in it?
        <br /><br />
        But before that, what are the implications of our words concerning the
        evolution of technology, and what is the role of design in it? Is design
        the physical appearance of the being; or the concept of being in the
        surrounding?
        <br /><br />
        There is a complex interplay between design, technology and humans which
        creates an impact on every individual who accommodates these changes for
        their: need, want, desire or (in compulsion).
        <br /><br />
        The concept of being something as a creation for usage is a notion that
        can be considered as technology. There can be an aspect of it which can
        be unravelled to understand the co-existence of design and tech through
        lived experiences and interactions of humans with their consideration of
        technology.
        <br /><br />
        With these questions, I intend to understand the transition of
        technology with lived human experiences in the forms of anecdotes, oral
        history and storytelling by facilitating conversations using the
        artefacts to understand the encounter of change with time. To make a
        collective sense of these overlapping experiences to understand the
        interplay of design and technology with time.
      </div>
    </div>
    <hr />
    <div class="repo-description">
      <div class="repo-description-head">
        connecting dots: for knowing the presence
      </div>
      <div class="repo-description-body">
        To gain an initial understanding of the topic, I decided to explore and
        observe the interactions between human and non-human elements in my
        surroundings and observe the reciprocal effects of these interactions.
        Through this process, I discovered that different age groups have
        varying perspectives on the concept of design and technology, which
        depends on their individual interests and preferences.
        <br /><br />
        These insights led to a behavioural map of two different houses situated
        with different age groups. The observation underlines that the
        categorisation and consideration and phrases to use and talk about
        technology are different depending on their perception of technology and
        design.
        <br /><br />
        <table style="width: 100%">
          <tr>
            <td style="width: 50%">Age: 21-23</td>
            <td>Age:52: 54</td>
          </tr>
        </table>
        <img :src="ConnectingDotJam" class="ConnectingDotJam" />

        <br /><br />
        The underlined observations of behavioural mapping helped create anchor
        points and map them as stakeholders. Stakeholders were the elements
        through which I tried to intervene in the experiences of people and make
        a note of their interaction and understanding of technology. These
        elements initiated conversations about the understanding of technology
        from different perspectives. For the explanation of the understanding,
        the conversations led to anecdotes to express the understanding and
        experience through stories and objects.

        <br /><br />
        <table style="width: 100%">
          <tr>
            <td class="connection"><img :src="Connection" /></td>
          </tr>
        </table>
        <br />
        Every anecdote explained the transition of the experiences and
        interactions with the transitioning mediums. With more stories, there
        were more objects, not the experience but the objects overlapping in
        different stories explaining different needs and experiences. This made
        me wonder how overlapping the same artefacts in different circumstances
        with different experiences not just explains the transition of the
        medium but also focuses on the usage of the object and underlines and
        questions the changing functionalities.
        <br /><br />
        So I decided to create an interactive space where one experiences the
        same stories with the overlapping of experiences and different artefacts
        to focus on non-linear transition, which triggers and highlights the
        evolving needs and functionalities.
        <br /><br />
        ru-ba-ru is a repository of lived experience with the history of
        artefacts as an interactive space and part of my thesis that helps to
        understand the transition and evolution of technology and the
        overlapping role of design in this journey.
      </div>
    </div>
    <hr />
    <div class="repo-description">
      <div class="repo-description-head">making sense: with the presence</div>
      <div class="repo-description-body">
        Survival is a basic human need, for the need to survive, humans have the
        understanding to seek and form attachments in the social environment,
        which shapes and directs their social interactions and attachment to
        people and objects in the form of social relationships. The human
        attribute to adapt to survive in better conditions gets influenced by
        the initial pre-defined attachment, and this definition and argument of
        survival act as a base for future choices in different settings. Human
        choices are attributed to their past and present experiences for looking
        forward to future experiences; in a world full of possibilities, the
        human need for survival(better) makes sense of these possibilities.
        <br /><br />
        Human experiences and actions add uniqueness and diversity, which adds
        identity and character to the physical structure of any object, system
        or innovation. It outlines the interaction and attachment that gives
        understanding and sense to the being of the creation or technology.
        Technology is a multifaceted concept that can be defined in various ways
        with different experiences, as it doesn't have a linear existence. It
        can be understood through its tangibility and its interaction with its
        form constituting engineering and physical design. And with the concept
        of being in the surrounding and triggering changes with its existence.
        The relationship of design with technology co-exists in both scenarios
        where in one place, it acts as the physical expression of the creation
        in the other conceptualisation of the existence of the creation.
        <br /><br />
        Looking at the overlapping transition of mediums and the evolution of
        technology, one can explain that the understanding of usage creates
        transition. The transition of medium with usage highlights the
        conceptualisation of the existence of the creation, which is design.
        Design is a core constituent of changing technology.
        <br /><br />
        Design can not be defined with one word or meaning. It is the physical
        appearance but also the functionality of the appearance. It is a
        transitional change with a stagnant appearance. Design is circumstantial
        it changes with changing situations and transitions. With the evolution
        of technology, there is also the evolution of design that explains the
        transition of the mediums, with their usage and functionalities, which
        influence their surroundings and trigger change.
      </div>
      <div class="repo-description-body sher">
        Dar ham ko bhī lagtā hai raste ke sannāTe se
        <br />
        lekin ek safar par ai dil ab jaanā to hog
        <br />
        -Jawed Akhtar
      </div>
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
  <div class="repo-description" ref="tvRepository" v-show="isTVVisible">
    <div class="repo-description-head">
      <img :src="TVRepo" />
    </div>
    <div class="repo-description-head-text">TELEVISION</div>
    <div class="repo-description-head margin-top-50">
      “ an anecdote of change in experience with circumstances, time and with
      innovation”
    </div>
    <div class="repo-description-body">
      I always wanted to read literature, but I was never able to concentrate on
      written pieces of text. Something or the other used to distract me. So,
      the theatre was a great alternative where audio and visuals accompanied by
      people performing it in front can not distract. I started watching
      Nukkad-natak and theatre in school with stories of Premchand that caught
      my attention. Nukkad Nataks were very similar to theatre but without sets
      and instruments, and sound. People used to perform around the city with
      instruments, the Natak's were mostly about spreading awareness for
      something, but they had narratives and dialogues. Same as college theatre,
      cinema was also an option, but due to limited safety, I was never allowed
      to go to a cinema hall alone or with my friends. And my family was not a
      big fan of movies, so the exposure to the cinema for me was way later. All
      these changes were interesting. It was a change in experience with
      circumstances and time and with innovation. Every medium had a different
      experience, which required the new medium's accommodation. TV with shoes,
      news and movies was really a lifesaver, as the convenience of it, I can
      watch in my own house. I religiously used to watch movies every weekend on
      Doordarshan, and then VCR and visual movies cassette had so many stories.
      On weekends, the whole neighbourhood rents a VCR and watches movies back
      to back the whole night. It creates a sense of similarity and community.
      From VCR to dish cable to having choices of more to be entertained with to
      DVD to cinema halls changing to multiplex, to torrent downloaded content
      on a laptop to using OTT subscriptions, it was an adventurous transition.
      Where each change was about the same yet felt very different. I remember
      how before school, I used to find in the newspaper the timings of movies
      and shows on each channel. So that when I come back, I can plan my
      afternoon. Now everything is available on the internet and applications.
      From theatre to Nukkad-Nataks to movies to TV shows to web series,
      everything is available on one screen without any hustle. It had been a
      joinery of convenience where I was not allowed to watch movies in halls,
      and now I have the option of watching it on my tablet on an OTT platform
      in my bed.
      <br /><br />
      <img class="tv-cinema" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of changes with the evolution of need and convenience for
      being informed”
    </div>
    <div class="repo-description-body">
      Newspaper is part of my daily routine and news is a very essential part of
      my day. With new devices coming in, the medium of news kept on changing,
      but still, morning tea and newspaper are the two things that are a daily
      ritual for me, Newspaper, as a print media, was accompanied by radio that
      could provide the latest updates on important national and international
      events. The transition to radio was to get instant updates on important
      national events. I was amazed when the counting of elections was updated
      on the radio after a while, and it created a sense of a system that was
      transparent. Then we bought a TV not just for news but for entertainment
      and sports, there were shows, music, cricket and news. A show with a
      reporter explaining the news with visuals and ground reports made it more
      reliable and interesting. But still, the newspaper was a part of my day.
      Newspaper, radio, and television were changed with the evolution of need.
      I never found the need to change my radio, but I had to change my
      television to the new one was a coloured TV, because the last portable one
      was not repairable and both of the new ones almost cost the same. The
      coloured TV was soon accompanied by cable and then set-top box with more
      options of news channels and reporters and also the news. The change from
      radio to coloured tv was not complicated. It offered more choices with
      time. For a while, these were the only option for news, and then very
      recently, I got a smartphone, and it has a bundle of applications for
      everything. Even for news and news channels, my son made my WhatsApp and
      Facebook accounts. Both of these platforms informed about news through
      varied sources, which was overwhelming and interesting. In times of
      pandemic, we stopped buying newspaper with the fear of transmitting
      infection, and these mediums TV and mobile application, became the only
      source of news. My radio works, but there are no news shows not as
      indulging as television news programs. As my last TV was replaced because
      it was not repairable anymore. Similarly, my sons bought a smart TV that
      works with the Internet. As we already had a router paying for the set-top
      box was a waste. Smart TV has youtube, and youtube has all news and news
      channels, but operating this new system is very hectic. Similarly, like
      the phone, this TV has applications. It has a lot of exhausting processes
      to type and add open something, and voice interaction not always works
      properly. So if there is no one to help or put what I want, I stick to my
      phone. With a lot of practice, I know how to open an application and use
      it. But still, newspaper is part of my day with my morning tea.
      <br /><br />
      <img class="tv-newspaper" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of transition of medium for the love of listening songs and
      finding similar experience”
    </div>
    <div class="repo-description-body">
      I always aspired to be a singer. It wasn't because I am professionally
      trained or have a melodious voice. It was just that music and rhythms
      fascinated me. My exposure to music or songs was limited to family
      functions, weddings, fairs and theatre. My exposure to listening music was
      very limited. Unfortunately, there were no vocational subjects in my
      school music, and my parents never permitted me to practice and learn
      music anywhere else.
      <br />In my wedding, I received a two-in-one radio and cassette player as
      a wedding gift. That creation amazed me completely. It had buttons and
      navigators to change stations and forward songs with the cassette. There
      were radio programs which had dedicated time for music shows and their
      artists. I had a library of cassettes categorised with movies and artists.
      I could play any of those cassettes and have the choice of listening to
      songs and enjoying the melodious peace the whole day at home. Then after a
      few years, I was gifted a Walkman with earphones. The choice of listening
      then became convenient for me. I could listen to music in bed without
      disturbing anyone and easily change the cassette or song while working in
      my kitchen or on my terrace. <br />Walkman and radio together were my
      sources of melodious peace. Then we bought a television to watch the news
      and sports. It also telecasted movies and shows with music and songs. Then
      a show was telecasted with the name “Chitrahar” on Doordarshan. It had
      stories of the songs and their making. It was a completely different
      experience. <br />I used to use TV, radio, and Walkman on different days
      with different needs. This became a routine, with cable and more options
      to choose from. Then I bought a cell phone to communicate with my near and
      dear ones. Apart from communication, that little thing had many options
      games, contact and a music player with some pre-installed tunes.
      <br />With the upgrade of the phone, suddenly, those tunes become songs,
      and one can download and transfer them. I never understood what Bluetooth
      is, but my children used it to transfer my songs to my phone or memory
      card. Managing cassettes was tough, and songs on the phone were a feasible
      option with proper labels and categorisation of artists and names. Then
      the MP3 player made it more viable without the hustle of cassette music on
      the fingertip while travelling and walking. Also, more choices were
      possible in one device. But I did not stop using the radio. It had a feel
      to it to hear by choosing cassettes and listening to songs. Then I had a
      smartphone with applications like youtube and Spotify and a Bluetooth
      speaker with it to play music. It looked interesting, but it was too hard
      to navigate for connections. <br />Recently, a friend gifted me an Alexa,
      which allows me to control my music choices by simply asking it to play a
      song or change the music. It's amazing to see how technology has evolved.
      It is a very similar experience to Bluetooth speakers and radio, but I can
      very easily ask it and have varied choices of songs to listen to.<br /><br />
      <img class="tv-song" src="" />
    </div>
    <div class="repo-description-body">
      <img class="tv-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>

  <div class="repo-description" ref="radioRepository" v-show="isRadioVisible">
    <div class="repo-description-head">
      <img :src="RadioRepo" />
    </div>
    <div class="repo-description-head-text">RADIO</div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of transition of medium for the love of listening songs and
      finding similar experience”
    </div>
    <div class="repo-description-body">
      I always aspired to be a singer. It wasn't because I am professionally
      trained or have a melodious voice. It was just that music and rhythms
      fascinated me. My exposure to music or songs was limited to family
      functions, weddings, fairs and theatre. My exposure to listening music was
      very limited. Unfortunately, there were no vocational subjects in my
      school music, and my parents never permitted me to practice and learn
      music anywhere else.
      <br />In my wedding, I received a two-in-one radio and cassette player as
      a wedding gift. That creation amazed me completely. It had buttons and
      navigators to change stations and forward songs with the cassette. There
      were radio programs which had dedicated time for music shows and their
      artists. I had a library of cassettes categorised with movies and artists.
      I could play any of those cassettes and have the choice of listening to
      songs and enjoying the melodious peace the whole day at home. Then after a
      few years, I was gifted a Walkman with earphones. The choice of listening
      then became convenient for me. I could listen to music in bed without
      disturbing anyone and easily change the cassette or song while working in
      my kitchen or on my terrace. <br />Walkman and radio together were my
      sources of melodious peace. Then we bought a television to watch the news
      and sports. It also telecasted movies and shows with music and songs. Then
      a show was telecasted with the name “Chitrahar” on Doordarshan. It had
      stories of the songs and their making. It was a completely different
      experience. <br />I used to use TV, radio, and Walkman on different days
      with different needs. This became a routine, with cable and more options
      to choose from. Then I bought a cell phone to communicate with my near and
      dear ones. Apart from communication, that little thing had many options
      games, contact and a music player with some pre-installed tunes.
      <br />With the upgrade of the phone, suddenly, those tunes become songs,
      and one can download and transfer them. I never understood what Bluetooth
      is, but my children used it to transfer my songs to my phone or memory
      card. Managing cassettes was tough, and songs on the phone were a feasible
      option with proper labels and categorisation of artists and names. Then
      the MP3 player made it more viable without the hustle of cassette music on
      the fingertip while travelling and walking. Also, more choices were
      possible in one device. But I did not stop using the radio. It had a feel
      to it to hear by choosing cassettes and listening to songs. Then I had a
      smartphone with applications like youtube and Spotify and a Bluetooth
      speaker with it to play music. It looked interesting, but it was too hard
      to navigate for connections. <br />Recently, a friend gifted me an Alexa,
      which allows me to control my music choices by simply asking it to play a
      song or change the music. It's amazing to see how technology has evolved.
      It is a very similar experience to Bluetooth speakers and radio, but I can
      very easily ask it and have varied choices of songs to listen to.
    </div>
    <div class="repo-description-body">
      <img class="radio-song" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of changes with the evolution of need and convenience for
      being informed”
    </div>
    <div class="repo-description-body">
      Newspaper is part of my daily routine and news is a very essential part of
      my day. With new devices coming in, the medium of news kept on changing,
      but still, morning tea and newspaper are the two things that are a daily
      ritual for me, Newspaper, as a print media, was accompanied by radio that
      could provide the latest updates on important national and international
      events. The transition to radio was to get instant updates on important
      national events. I was amazed when the counting of elections was updated
      on the radio after a while, and it created a sense of a system that was
      transparent. Then we bought a TV not just for news but for entertainment
      and sports, there were shows, music, cricket and news. A show with a
      reporter explaining the news with visuals and ground reports made it more
      reliable and interesting. But still, the newspaper was a part of my day.
      Newspaper, radio, and television were changed with the evolution of need.
      I never found the need to change my radio, but I had to change my
      television to the new one was a coloured TV, because the last portable one
      was not repairable and both of the new ones almost cost the same. The
      coloured TV was soon accompanied by cable and then set-top box with more
      options of news channels and reporters and also the news. The change from
      radio to coloured tv was not complicated. It offered more choices with
      time. For a while, these were the only option for news, and then very
      recently, I got a smartphone, and it has a bundle of applications for
      everything. Even for news and news channels, my son made my WhatsApp and
      Facebook accounts. Both of these platforms informed about news through
      varied sources, which was overwhelming and interesting. In times of
      pandemic, we stopped buying newspaper with the fear of transmitting
      infection, and these mediums TV and mobile application, became the only
      source of news. My radio works, but there are no news shows not as
      indulging as television news programs. As my last TV was replaced because
      it was not repairable anymore. Similarly, my sons bought a smart TV that
      works with the Internet. As we already had a router paying for the set-top
      box was a waste. Smart TV has youtube, and youtube has all news and news
      channels, but operating this new system is very hectic. Similarly, like
      the phone, this TV has applications. It has a lot of exhausting processes
      to type and add open something, and voice interaction not always works
      properly. So if there is no one to help or put what I want, I stick to my
      phone. With a lot of practice, I know how to open an application and use
      it. But still, newspaper is part of my day with my morning tea.
    </div>
    <div class="repo-description-body">
      <img class="radio-news" src="" />
    </div>
    <div class="repo-description-body">
      <img class="radio-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
  <div class="repo-description" ref="emailRepository" v-show="isEmailVisible">
    <div class="repo-description-head">
      <img :src="EmailRepo" />
    </div>
    <div class="repo-description-head-text">MESSAGES</div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of transitioning of message with changing mediums and
      changing functionalities”
    </div>
    <div class="repo-description-body">
      SMS, messages, texts and snaps had multiple transitions with the exposure
      of evolving mediums. I was almost eight years old when I saw the first
      cellphone in my small lived life. I lack a white interface with 12 buttons
      with a lot of functions, but the one that caught my attention was the
      section of the game with a snake. But the thing that caught my parent's
      attention was my thin and small fingers. They gave me a telephone book
      with almost 80-100 contacts and appointed me to save all those contacts
      into the phone in a week, and they will take me to an amusement park. It
      was a tiresome and interactive job, but that made my fingers adaptable to
      that keyboard. With the practice of a week, I had speed in typing
      messages. Later when cellphone became the primary mode of communication,
      and I had the exposure of cellphone to talk with my friends. Texting
      seemed more private to a ten year old than talking on a phone call. It was
      a cool choice back then. Since then till today, texting messages have been
      the main way of communication with people, even if we are in the same
      house. Back then, it was cool, and today it is just normal. My first
      personal phone was a qwerty keyboard phone, and it was almost impossible
      to type without a typo on it. It was very hard and complicated to type on
      it, so I took my parent's old phone with a landscape slide keyboard. It
      was better than the last one to type, but this one gave pain to my hands
      with holding it. The first smartphone that I used was, of course, a used
      phone of my parents but it was still a wonderful peace of creation. With
      WhatsApp, I was able to send audio and video message without the whole
      effort of typing with the keyboard. With the smartphone, the experience of
      typing messages and texting changed. It became interesting and indulging
      with customising keyboard that could type in different languages with the
      input of English, to using applications like Hike, which had a feasible
      conversation with different packs of stickers. From text to image to audio
      to stickers to GIFs, the expression kept on evolving with time. With
      applications like Strickerly, it gave a personalised feel to create
      situational stickers and be able to share them with selected people.
      Applications like telegram and discord added to the idea of community as
      they allowed to create sever and connect with a cluster of friends and be
      able to communicate with them with multiple medium of messages. Especially
      during the pandemic, platforms like Snapchat and Instagram, with their
      features of snap texting and sharing, created a sense of inclusion in
      isolation and evolved the idea of messages with added functionalities of
      contained privacy. It started with SMS, as a cool choice but with added
      and changing functionalities, it has become a necessary norm.
    </div>
    <div class="repo-description-body">
      <img class="email-texts" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “ an anecdote of expressing emotions with changing mediums with
      feasibility”
    </div>
    <div class="repo-description-body">
      Writing is a way to express emotions for me, and letters are one of the
      forms of those expressions. I lost both of my parents when I was young, so
      to express myself, I used to write to them and float the paper like a boat
      in the river. With time it became a habit, and I just started writing
      letters to everyone. The envelopes and postal stamps fascinated me. That
      was interesting at that instance. I did not consider letters as a form of
      technology, but indeed a letter ready to post in an envelope and a stamp
      is a technology. While I was out studying in Delhi, that was a mode for me
      to communicate with people because phone calls were very expensive. So
      letters became a mode of communication and expression both. Even when
      telephones and cellphones became casual. I kept on writing letters as a
      gesture with gifts to my near ones. I guess I express myself better with
      writing. When the cellphone had the new feature of SMS, I gave that a try
      but with 26 alphabets in 9 buttons, I preferred to stick to letters, as it
      was hectic and also there was a missing feel to it. When I decided to
      migrate to a different city with 'adulting' and new opportunities, I asked
      my friends and peers for their addresses, and surprisingly I got two. One
      was the postal address, and the other was email. I liked the concept of
      email, it was kind of a e-lettter, but typing emotions on a laptop did not
      really replicate the feeling or the expression of handwritten letters. I
      stuck to my old method of sending occasional letters with gifts to my
      people. Later when I shifted to a different country, an international
      courier was not very feasible. I tried audio messages with a chat
      application with verbal expressions and feelings. It was new and
      expressive, but it had something missing, at least for me. So then I stuck
      back to emails as typing did not provide the expression. I tried using
      text-to-speech models, which typed for me, but with its understanding of
      my emotions with the auto-correction lost the essence of my message. With
      Ipad, this became really feasible for me. I started writing letters with
      Ipad. With it, I had the feeling of writing and expressing myself with a
      pen but very much replicated the experience, and I used to send the same
      converted pdf as attachments. I tried different mediums, and now when I
      look back, nothing really had the feeling of writing on paper with the
      ink. I kept on changing my medium with my need and seeking a similar
      experience. Now my emails are attached with digital handwritten letters or
      scanned handwritten letters, depending on the feasibility of the
      situation.
    </div>
    <div class="repo-description-body">
      <img class="email-letter" src="" />
    </div>
    <div class="repo-description-body">
      <img class="email-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
  <div class="repo-description" ref="alexaRepository" v-show="isAlexaVisible">
    <div class="repo-description-head">
      <img :src="AlexaRepo" />
    </div>
    <div class="repo-description-head-text">ALEXA</div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of transitioning of message with changing mediums and
      changing functionalities”
    </div>
    <div class="repo-description-body">
      Electricity was the innovation that provided us artificial light and air,
      and later a lot of innovative need-of-the-hour inventions emerged with the
      invention. With my remembrance, the first invention of light that I
      encountered was the bulb with a fuse, emitting bright yellow light. That
      invention turned out to be a miracle for me. Indeed it was a major
      discovery for everyone, but without a light bulb, my life would have
      turned a bit differently. I was merely 14 when I had an accident while
      hiking, and I hurt my face badly with almost a month in hospital and
      stitches. That accident affected my jaws, teeth and vision, and I had a
      cylindrical +8 eye power. Everything without spectacles was blurry, but
      with it and without brightness, everything was merely blobs in dim light.
      So the light bulb was a miraculous invention for me. Although the
      traditional bulb provided good visibility, it had its issues. It used to
      emit a lot of heat, which would warm up the room and consume a lot of
      electricity. So, whenever there was a new medium which could provide
      better visibility and was efficient, I used to switch to a new device.
      From CFL to tube lights to LED bulbs, the transition of the medium was for
      a sustainable and pocket-friendly efficient choice. Then Side stand lamps
      were a very interesting and useful addition to my home. Since I need light
      all the time, the idea of diffusing the colour of light with a lamp was
      really aesthetical and helpful at the same time. This change was essential
      for my condition, as there was some control of the brightness, and it made
      a significant difference in my daily life. In addition to the side stand
      lamps, I also installed fall ceiling lights and spotlights, which were all
      trending changes that made me try something new, but it was not very
      useful to my usage and convenience. Recently my daughter gifted a
      Bluetooth light bulb. Initially, I used it just as any normal led bulb
      controlled by a normal socket. Then later, she connected the bulb and
      installed an application on my phone. A white bulb can be customised to
      emit yellow or any other possible colour with my phone. With personalised
      customisation, she was able to set different modes for reading, sleeping,
      or watching TV. The best part is that a 12 W LED bulb can behave like a 5
      W bulb, making it energy efficient and cost-effective, and easy to use all
      day. It took me a while to understand the application. With all these
      functions with Bluetooth and wifi, that bulb could change colours and
      brightness. It was very interesting to see but very complicated to use
      every time it needed multiple access and permissions. So I continued using
      it as a normal led bulb. One day, one of my guests connected the unused
      Alexa of the house to the bulb, and now I can simply say, "Alexa, switch
      on/off the light, set the mode and brightness," and it does everything for
      me. This solved the complexity of the application because when I was not
      able to connect or set it up, I used to send a screenshot to my daughter.
      And her instructions not always worked. Now, Alexa can manage all of that
      as it only manages light, making it so much easier for me.
    </div>
    <div class="repo-description-body">
      <img class="alexa-light" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of transition of medium for the love of listening songs and
      finding similar experience”
    </div>
    <div class="repo-description-body">
      I always aspired to be a singer. It wasn't because I am professionally
      trained or have a melodious voice. It was just that music and rhythms
      fascinated me. My exposure to music or songs was limited to family
      functions, weddings, fairs and theatre. My exposure to listening music was
      very limited. Unfortunately, there were no vocational subjects in my
      school music, and my parents never permitted me to practice and learn
      music anywhere else.
      <br />In my wedding, I received a two-in-one radio and cassette player as
      a wedding gift. That creation amazed me completely. It had buttons and
      navigators to change stations and forward songs with the cassette. There
      were radio programs which had dedicated time for music shows and their
      artists. I had a library of cassettes categorised with movies and artists.
      I could play any of those cassettes and have the choice of listening to
      songs and enjoying the melodious peace the whole day at home. Then after a
      few years, I was gifted a Walkman with earphones. The choice of listening
      then became convenient for me. I could listen to music in bed without
      disturbing anyone and easily change the cassette or song while working in
      my kitchen or on my terrace. <br />Walkman and radio together were my
      sources of melodious peace. Then we bought a television to watch the news
      and sports. It also telecasted movies and shows with music and songs. Then
      a show was telecasted with the name “Chitrahar” on Doordarshan. It had
      stories of the songs and their making. It was a completely different
      experience. <br />I used to use TV, radio, and Walkman on different days
      with different needs. This became a routine, with cable and more options
      to choose from. Then I bought a cell phone to communicate with my near and
      dear ones. Apart from communication, that little thing had many options
      games, contact and a music player with some pre-installed tunes.
      <br />With the upgrade of the phone, suddenly, those tunes become songs,
      and one can download and transfer them. I never understood what Bluetooth
      is, but my children used it to transfer my songs to my phone or memory
      card. Managing cassettes was tough, and songs on the phone were a feasible
      option with proper labels and categorisation of artists and names. Then
      the MP3 player made it more viable without the hustle of cassette music on
      the fingertip while travelling and walking. Also, more choices were
      possible in one device. But I did not stop using the radio. It had a feel
      to it to hear by choosing cassettes and listening to songs. Then I had a
      smartphone with applications like youtube and Spotify and a Bluetooth
      speaker with it to play music. It looked interesting, but it was too hard
      to navigate for connections. <br />Recently, a friend gifted me an Alexa,
      which allows me to control my music choices by simply asking it to play a
      song or change the music. It's amazing to see how technology has evolved.
      It is a very similar experience to Bluetooth speakers and radio, but I can
      very easily ask it and have varied choices of songs to listen to.
    </div>
    <div class="repo-description-body">
      <img class="alexa-song" src="" />
    </div>
    <div class="repo-description-body">
      <img class="alexa-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
  <div class="repo-description" ref="mobileRepository" v-show="isMobileVisible">
    <div class="repo-description-head">
      <img :src="MobileRepo" />
    </div>
    <div class="repo-description-head-text">MOBILE</div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of transitioning of message with changing mediums and
      changing functionalities”
    </div>
    <div class="repo-description-body">
      SMS, messages, texts and snaps had multiple transitions with the exposure
      of evolving mediums. I was almost eight years old when I saw the first
      cellphone in my small lived life. I lack a white interface with 12 buttons
      with a lot of functions, but the one that caught my attention was the
      section of the game with a snake. But the thing that caught my parent's
      attention was my thin and small fingers. They gave me a telephone book
      with almost 80-100 contacts and appointed me to save all those contacts
      into the phone in a week, and they will take me to an amusement park. It
      was a tiresome and interactive job, but that made my fingers adaptable to
      that keyboard. With the practice of a week, I had speed in typing
      messages. Later when cellphone became the primary mode of communication,
      and I had the exposure of cellphone to talk with my friends. Texting
      seemed more private to a ten year old than talking on a phone call. It was
      a cool choice back then. Since then till today, texting messages have been
      the main way of communication with people, even if we are in the same
      house. Back then, it was cool, and today it is just normal. My first
      personal phone was a qwerty keyboard phone, and it was almost impossible
      to type without a typo on it. It was very hard and complicated to type on
      it, so I took my parent's old phone with a landscape slide keyboard. It
      was better than the last one to type, but this one gave pain to my hands
      with holding it. The first smartphone that I used was, of course, a used
      phone of my parents but it was still a wonderful peace of creation. With
      WhatsApp, I was able to send audio and video message without the whole
      effort of typing with the keyboard. With the smartphone, the experience of
      typing messages and texting changed. It became interesting and indulging
      with customising keyboard that could type in different languages with the
      input of English, to using applications like Hike, which had a feasible
      conversation with different packs of stickers. From text to image to audio
      to stickers to GIFs, the expression kept on evolving with time. With
      applications like Strickerly, it gave a personalised feel to create
      situational stickers and be able to share them with selected people.
      Applications like telegram and discord added to the idea of community as
      they allowed to create sever and connect with a cluster of friends and be
      able to communicate with them with multiple medium of messages. Especially
      during the pandemic, platforms like Snapchat and Instagram, with their
      features of snap texting and sharing, created a sense of inclusion in
      isolation and evolved the idea of messages with added functionalities of
      contained privacy. It started with SMS, as a cool choice but with added
      and changing functionalities, it has become a necessary norm.
    </div>
    <div class="repo-description-body">
      <img class="mobile-m1" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of changing technology for usage, experience and compulsion”
    </div>
    <div class="repo-description-body">
      Mobile phones have become a very integral part of my life. Telephone to
      smartphones has been a long and fast journey that I have transitioned.
      Telephone to wireless telephones, to cellphones, to multimedia phones and
      touch phones was a change that induced my curiosity and needs together for
      me. Changes in devices and mediums for me used to happen when they were
      non-repairable or something new looked very fascinating in comparison to
      others. This was true till I used a phone for my curiosity, but now it has
      become a need. Till today whenever I change a smartphone, it is not
      because it is broken, it is just not compatible with the ‘new’, being
      completely fine. Change has become a compulsion for something better,
      which is a need. The core idea of the phone was to communicate, but with
      time the ways of communication evolved with features and experience.
      Slowly smartphone has become the platform for everything. I never thought
      that a device I used for calling and communicating would become a key
      element of my job. I am a teacher, and technology has never been my forte,
      as it is complicated and intimidating at the same time. It took me time to
      create and learn the flow of symbols like green is to accept, red is to
      reject, ok is to send with a smartphone and WhatsApp as an application
      just a messaging platform to be connected to people and send them good
      morning messages. Suddenly that became a platform to report on my duty.
      Being a teacher and headmaster, I had to provide evidence of attendance on
      WhatsApp. From one group, it transitioned to the number of groups at the
      city, state and block levels, providing different data sets, in written
      and digital. Then the pattern escalated from WhatsApp to an independent
      application with login and uploading data on systems, which was not a
      familiar cup of tea for me. I kept on sending screenshots to my daughter,
      and she used to narrate stepwise what was next with another screenshot.
      With the pandemic, the phone became the department not just for teaching
      but for providing all kinds of data sets digitally. With no access to
      cyber cafes and no training in computer software whole of my staff created
      a spreadsheet of handwritten data and asked the tech heads of our
      respective houses to convert it digitally for submission. This all looked
      needed but was also felt compulsive at the same time. Now everything
      meetings, teaching, exams, results, and documents happen through this
      mobile phone or I should say, smartphones. Every year I keep changing a
      perfectly fine-looking phone because externally, it may look nice, but
      internally it is not compatible with my compulsive needs as there is
      always a need for more. I don’t understand how this need keeps on
      increasing, but this change has become a compulsion, which is
      transitioning.
    </div>
    <div class="repo-description-body">
      <img class="mobile-m2" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of changes with the evolution of need and convenience for
      being informed”
    </div>
    <div class="repo-description-body">
      I always aspired to be a singer. It wasn't because I am professionally
      trained or have a melodious voice. It was just that music and rhythms
      fascinated me. My exposure to music or songs was limited to family
      functions, weddings, fairs and theatre. My exposure to listening music was
      very limited. Unfortunately, there were no vocational subjects in my
      school music, and my parents never permitted me to practice and learn
      music anywhere else.
      <br />In my wedding, I received a two-in-one radio and cassette player as
      a wedding gift. That creation amazed me completely. It had buttons and
      navigators to change stations and forward songs with the cassette. There
      were radio programs which had dedicated time for music shows and their
      artists. I had a library of cassettes categorised with movies and artists.
      I could play any of those cassettes and have the choice of listening to
      songs and enjoying the melodious peace the whole day at home. Then after a
      few years, I was gifted a Walkman with earphones. The choice of listening
      then became convenient for me. I could listen to music in bed without
      disturbing anyone and easily change the cassette or song while working in
      my kitchen or on my terrace. <br />Walkman and radio together were my
      sources of melodious peace. Then we bought a television to watch the news
      and sports. It also telecasted movies and shows with music and songs. Then
      a show was telecasted with the name “Chitrahar” on Doordarshan. It had
      stories of the songs and their making. It was a completely different
      experience. <br />I used to use TV, radio, and Walkman on different days
      with different needs. This became a routine, with cable and more options
      to choose from. Then I bought a cell phone to communicate with my near and
      dear ones. Apart from communication, that little thing had many options
      games, contact and a music player with some pre-installed tunes.
      <br />With the upgrade of the phone, suddenly, those tunes become songs,
      and one can download and transfer them. I never understood what Bluetooth
      is, but my children used it to transfer my songs to my phone or memory
      card. Managing cassettes was tough, and songs on the phone were a feasible
      option with proper labels and categorisation of artists and names. Then
      the MP3 player made it more viable without the hustle of cassette music on
      the fingertip while travelling and walking. Also, more choices were
      possible in one device. But I did not stop using the radio. It had a feel
      to it to hear by choosing cassettes and listening to songs. Then I had a
      smartphone with applications like youtube and Spotify and a Bluetooth
      speaker with it to play music. It looked interesting, but it was too hard
      to navigate for connections. <br />Recently, a friend gifted me an Alexa,
      which allows me to control my music choices by simply asking it to play a
      song or change the music. It's amazing to see how technology has evolved.
      It is a very similar experience to Bluetooth speakers and radio, but I can
      very easily ask it and have varied choices of songs to listen to.
    </div>
    <div class="repo-description-body">
      <img class="mobile-m3" src="" />
    </div>
    <div class="repo-description-body">
      <img class="mobile-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
  <!--- LaptopView -->
  <div class="repo-description" ref="laptopRepository" v-show="isLaptopVisible">
    <div class="repo-description-head">
      <img :src="LaptopRepo" />
    </div>
    <div class="repo-description-head-text">LAPTOP</div>
    <div class="repo-description-head margin-top-50">
      “ an anecdote of change in experience with circumstances, time and with
      innovation”
    </div>
    <div class="repo-description-body">
      I always wanted to read literature, but I was never able to concentrate on
      written pieces of text. Something or the other used to distract me. So,
      the theatre was a great alternative where audio and visuals accompanied by
      people performing it in front can not distract. I started watching
      Nukkad-natak and theatre in school with stories of Premchand that caught
      my attention. Nukkad Nataks were very similar to theatre but without sets
      and instruments, and sound. People used to perform around the city with
      instruments, the Natak's were mostly about spreading awareness for
      something, but they had narratives and dialogues. Same as college theatre,
      cinema was also an option, but due to limited safety, I was never allowed
      to go to a cinema hall alone or with my friends. And my family was not a
      big fan of movies, so the exposure to the cinema for me was way later. All
      these changes were interesting. It was a change in experience with
      circumstances and time and with innovation. Every medium had a different
      experience, which required the new medium's accommodation. TV with shoes,
      news and movies was really a lifesaver, as the convenience of it, I can
      watch in my own house. I religiously used to watch movies every weekend on
      Doordarshan, and then VCR and visual movies cassette had so many stories.
      On weekends, the whole neighbourhood rents a VCR and watches movies back
      to back the whole night. It creates a sense of similarity and community.
      From VCR to dish cable to having choices of more to be entertained with to
      DVD to cinema halls changing to multiplex, to torrent downloaded content
      on a laptop to using OTT subscriptions, it was an adventurous transition.
      Where each change was about the same yet felt very different. I remember
      how before school, I used to find in the newspaper the timings of movies
      and shows on each channel. So that when I come back, I can plan my
      afternoon. Now everything is available on the internet and applications.
      From theatre to Nukkad-Nataks to movies to TV shows to web series,
      everything is available on one screen without any hustle. It had been a
      joinery of convenience where I was not allowed to watch movies in halls,
      and now I have the option of watching it on my tablet on an OTT platform
      in my bed.
    </div>
    <div class="repo-description-body">
      <img class="laptop-l1" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “ an anecdote of changing mediums and experience for the comfort of
      reading”
    </div>
    <div class="repo-description-body">
      Reading books was a hobby that became a habit, from comics to storybooks
      to novels, all in the form of books. Which can be considered as
      technology, a technology that had content, stories, and literature which I
      used to sustain my loneliness with fictional characters. Sourcing books
      was a hard task. There were not many libraries around. And the ones who
      were there did not have all the preferred books. Also, the school library
      only allotted the book for a week, so it was not a convenient method. The
      alternate method was to get the Xerox of the remaining story or to find a
      group of five to six people who all have the same reading interests. With
      the introduction of the desktop and the Internet, it became different. The
      library had access to the Internet and computers. From the Internet, one
      can download pdfs from different websites. Also, save the pdfs in external
      storage. The experience of reading books on the desktop was not very
      convenient. The hardcopy book had its own comfort, texture and feeling of
      reading. Desktop did not really transition as the medium. It was a device
      that could do what a book can in a different medium. When computer got
      replaced by laptops, the laptop also acted as a medium to read books. It
      was comparatively convenient in comparison to the desktop, but still, it
      could not replicate the feeling of a printed book. With newer mediums, the
      experience of reading was changing, but that really was not comforting or
      preferable with the offered convenience. With smartphones being a normal
      device in every pocket, I also had one, I tried reading on my phone, but
      for me, it was too hard to concentrate on a small screen and read with the
      screen brightness. Because of that and my habit of reading, I was gifted a
      Kindle, this new very meant to the invention to-read e-books. It was as
      compatible as books but didn't have phone or laptop brightness. With the
      help of the Internet, one could sync books through e-mails, and the
      convenience of reading was an inbuilt dictionary. I also have my own
      e-book library on Kindle with all these features and convenience. It was
      different and interesting but not exactly a convenient device. It appears
      as a replication of a book, but then carrying or having just a device for
      reading is not so convenient. I realised this when I owned an iPad. I did
      not bought it for reading, but it ended up as convenient for reading. I
      can have cloud storage for all my books. I can read and annotate research
      papers and articles. Doodle characters on my books, and still don't feel
      bad about it as it is intangible. After all these transitions, I prefer to
      read on my iPad coz it's easy to do, but the idea of reading a physical
      book cannot be replaceable by any medium.
    </div>
    <div class="repo-description-body">
      <img class="laptop-l2" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of narrating thoughts with the convenience of change and
      transition and searching for relatability”
    </div>
    <div class="repo-description-body">
      I am a poet. I curate thoughts and put them in words and lines with pen on
      paper. Initially, I used to have a diary where all my thoughts,
      one-liners, quotes, stories, late-night thoughts, anecdotes and
      expressions were curated and stored in the form of poetries. With a spark
      of fire, all my thoughts and expressions were burnt in an accident in my
      house. I almost lost everything I wrote. Those thoughts were a unique
      piece of art that I also don't remember now. Then I decided to organise my
      poetries, and with my new job the computer was the very right equipment
      which could have a digital archive of all my work. I started translating
      my work into a digital archive. The problem with desktop computers was
      that they were not accessible everywhere. With my bad memory, whenever I
      had to share those poetries in a gathering, I needed to have physical
      writing, either handwritten or in print. So when there was a shift from
      computer to laptop, it became a new way for me to type my thoughts. They
      were convenient in a way whenever I had to read my poetries to my friends
      at a gathering, unlike the computer. But it was still double the work I
      used to write in diaries and then maintain a digital archive of my
      thoughts so I don't lose them. Smartphone being the new normal, was a new
      medium for me to store my creation. It was very convenient to type on the
      phone and share it with people whenever I wanted to. With the progression
      of smartphones, cloud storage was a miracle where there is no burden of
      backing up the work and creating handwritten archives. The experience of
      writing poetries on a phone and laptop with the keyboard was not really
      nice, as it felt like something was missing in the process. That
      experience was not natural, but for the need to maintain my thoughts in an
      organised manner, I had to use it. The English-typed Hindi poetries were
      still a problem. Then Google released the text input tool, where one types
      the alphabet in English and used to convert it to Hindi font with
      linguistic expression. So if I had to write हम I would type 'hum', which
      sounds like in Hindi in the English language. It was a great mode of
      technology that made my life easy and made me happy because it was easy
      then to type and give things for publishing or sharing or printing for
      myself, as I write in a different language. There were still a few
      eco-system issues, like when I used to copy my typed text to Microsoft
      Word, it was not possible to read as it did not had the support for the
      Google tool font. But if I would copy and paste the text to the tool back,
      I could read so it was still convenient. I was not familiar with Hindi
      typing on my laptop keyboard, so I had to use the same tool for converting
      the language of my text. Then I recently bought a tablet with a digital
      pen. I could write with that pen on the tablet, similar to a pen on a
      diary. I witness a very similar experience but not exactly the same. Paper
      and ink have a smell. The paper has a texture, and when a pen scribbles on
      it, there is a piece of music. This pen and tablet, though, have a similar
      experience and had the convenience of saving my handwritten poetries in
      cloud storage which can change with one scan to text a Hindi font text.
      This whole journey was a change led by need and convenience and finding
      reliability with the experience.
    </div>
    <div class="repo-description-body">
      <img class="laptop-l3" src="" />
    </div>
    <div class="repo-description-body">
      <img class="laptop-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
  <!-- ipadview -->
  <div class="repo-description" ref="ipadRepository" v-show="isIpadVisible">
    <div class="repo-description-head">
      <img :src="IpadRepo" />
    </div>
    <div class="repo-description-head-text">IPAD</div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of narrating thoughts with the convenience of change and
      transition and searching for relatability”
    </div>
    <div class="repo-description-body">
      I am a poet. I curate thoughts and put them in words and lines with pen on
      paper. Initially, I used to have a diary where all my thoughts,
      one-liners, quotes, stories, late-night thoughts, anecdotes and
      expressions were curated and stored in the form of poetries. With a spark
      of fire, all my thoughts and expressions were burnt in an accident in my
      house. I almost lost everything I wrote. Those thoughts were a unique
      piece of art that I also don't remember now. Then I decided to organise my
      poetries, and with my new job the computer was the very right equipment
      which could have a digital archive of all my work. I started translating
      my work into a digital archive. The problem with desktop computers was
      that they were not accessible everywhere. With my bad memory, whenever I
      had to share those poetries in a gathering, I needed to have physical
      writing, either handwritten or in print. So when there was a shift from
      computer to laptop, it became a new way for me to type my thoughts. They
      were convenient in a way whenever I had to read my poetries to my friends
      at a gathering, unlike the computer. But it was still double the work I
      used to write in diaries and then maintain a digital archive of my
      thoughts so I don't lose them. Smartphone being the new normal, was a new
      medium for me to store my creation. It was very convenient to type on the
      phone and share it with people whenever I wanted to. With the progression
      of smartphones, cloud storage was a miracle where there is no burden of
      backing up the work and creating handwritten archives. The experience of
      writing poetries on a phone and laptop with the keyboard was not really
      nice, as it felt like something was missing in the process. That
      experience was not natural, but for the need to maintain my thoughts in an
      organised manner, I had to use it. The English-typed Hindi poetries were
      still a problem. Then Google released the text input tool, where one types
      the alphabet in English and used to convert it to Hindi font with
      linguistic expression. So if I had to write हम I would type 'hum', which
      sounds like in Hindi in the English language. It was a great mode of
      technology that made my life easy and made me happy because it was easy
      then to type and give things for publishing or sharing or printing for
      myself, as I write in a different language. There were still a few
      eco-system issues, like when I used to copy my typed text to Microsoft
      Word, it was not possible to read as it did not had the support for the
      Google tool font. But if I would copy and paste the text to the tool back,
      I could read so it was still convenient. I was not familiar with Hindi
      typing on my laptop keyboard, so I had to use the same tool for converting
      the language of my text. Then I recently bought a tablet with a digital
      pen. I could write with that pen on the tablet, similar to a pen on a
      diary. I witness a very similar experience but not exactly the same. Paper
      and ink have a smell. The paper has a texture, and when a pen scribbles on
      it, there is a piece of music. This pen and tablet, though, have a similar
      experience and had the convenience of saving my handwritten poetries in
      cloud storage which can change with one scan to text a Hindi font text.
      This whole journey was a change led by need and convenience and finding
      reliability with the experience.
    </div>
    <div class="repo-description-body">
      <img class="ipad-l1" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “an anecdote of expressing emotions with changing mediums with
      feasibility”
    </div>
    <div class="repo-description-body">
      Writing is a way to express emotions for me, and letters are one of the
      forms of those expressions. I lost both of my parents when I was young, so
      to express myself, I used to write to them and float the paper like a boat
      in the river. With time it became a habit, and I just started writing
      letters to everyone. The envelopes and postal stamps fascinated me. That
      was interesting at that instance. I did not consider letters as a form of
      technology, but indeed a letter ready to post in an envelope and a stamp
      is a technology. While I was out studying in Delhi, that was a mode for me
      to communicate with people because phone calls were very expensive. So
      letters became a mode of communication and expression both. Even when
      telephones and cellphones became casual. I kept on writing letters as a
      gesture with gifts to my near ones. I guess I express myself better with
      writing. When the cellphone had the new feature of SMS, I gave that a try
      but with 26 alphabets in 9 buttons, I preferred to stick to letters, as it
      was hectic and also there was a missing feel to it. When I decided to
      migrate to a different city with 'adulting' and new opportunities, I asked
      my friends and peers for their addresses, and surprisingly I got two. One
      was the postal address, and the other was email. I liked the concept of
      email, it was kind of a e-lettter, but typing emotions on a laptop did not
      really replicate the feeling or the expression of handwritten letters. I
      stuck to my old method of sending occasional letters with gifts to my
      people. Later when I shifted to a different country, an international
      courier was not very feasible. I tried audio messages with a chat
      application with verbal expressions and feelings. It was new and
      expressive, but it had something missing, at least for me. So then I stuck
      back to emails as typing did not provide the expression. I tried using
      text-to-speech models, which typed for me, but with its understanding of
      my emotions with the auto-correction lost the essence of my message. With
      Ipad, this became really feasible for me. I started writing letters with
      Ipad. With it, I had the feeling of writing and expressing myself with a
      pen but very much replicated the experience, and I used to send the same
      converted pdf as attachments. I tried different mediums, and now when I
      look back, nothing really had the feeling of writing on paper with the
      ink. I kept on changing my medium with my need and seeking a similar
      experience. Now my emails are attached with digital handwritten letters or
      scanned handwritten letters, depending on the feasibility of the
      situation.
    </div>
    <div class="repo-description-body">
      <img class="ipad-l2" src="" />
    </div>
    <div class="repo-description-head margin-top-50">
      “ an anecdote of changing mediums and experience for the comfort of
      reading”
    </div>
    <div class="repo-description-body">
      Reading books was a hobby that became a habit, from comics to storybooks
      to novels, all in the form of books. Which can be considered as
      technology, a technology that had content, stories, and literature which I
      used to sustain my loneliness with fictional characters. Sourcing books
      was a hard task. There were not many libraries around. And the ones who
      were there did not have all the preferred books. Also, the school library
      only allotted the book for a week, so it was not a convenient method. The
      alternate method was to get the Xerox of the remaining story or to find a
      group of five to six people who all have the same reading interests. With
      the introduction of the desktop and the Internet, it became different. The
      library had access to the Internet and computers. From the Internet, one
      can download pdfs from different websites. Also, save the pdfs in external
      storage. The experience of reading books on the desktop was not very
      convenient. The hardcopy book had its own comfort, texture and feeling of
      reading. Desktop did not really transition as the medium. It was a device
      that could do what a book can in a different medium. When computer got
      replaced by laptops, the laptop also acted as a medium to read books. It
      was comparatively convenient in comparison to the desktop, but still, it
      could not replicate the feeling of a printed book. With newer mediums, the
      experience of reading was changing, but that really was not comforting or
      preferable with the offered convenience. With smartphones being a normal
      device in every pocket, I also had one, I tried reading on my phone, but
      for me, it was too hard to concentrate on a small screen and read with the
      screen brightness. Because of that and my habit of reading, I was gifted a
      Kindle, this new very meant to the invention to-read e-books. It was as
      compatible as books but didn't have phone or laptop brightness. With the
      help of the Internet, one could sync books through e-mails, and the
      convenience of reading was an inbuilt dictionary. I also have my own
      e-book library on Kindle with all these features and convenience. It was
      different and interesting but not exactly a convenient device. It appears
      as a replication of a book, but then carrying or having just a device for
      reading is not so convenient. I realised this when I owned an iPad. I did
      not bought it for reading, but it ended up as convenient for reading. I
      can have cloud storage for all my books. I can read and annotate research
      papers and articles. Doodle characters on my books, and still don't feel
      bad about it as it is intangible. After all these transitions, I prefer to
      read on my iPad coz it's easy to do, but the idea of reading a physical
      book cannot be replaceable by any medium.
    </div>
    <div class="repo-description-body">
      <img class="ipad-l3" src="" />
    </div>
    <div class="repo-description-body">
      <img class="ipad-text" src="" />
    </div>
    <div class="repo-description-body" style="text-align: center">
      <button class="btn btn-outline-secondary" @click="scrollToRepository">
        back to repository
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import RuBaaRuuHead from "@/assets/images/ru-baa-ruu.png";
import DownArrow from "@/assets/images/down-arrow.png";
import TVCover from "@/assets/images/tv-cover.png";
import TVHover from "@/assets/images/tv-hover.png";
import RadioCover from "@/assets/images/radio-cover.png";
import RadioHover from "@/assets/images/radio-hover.png";
import RepositoryCover from "@/assets/images/repository-background.png";
import EmailCover from "@/assets/images/email-cover.png";
import EmailHover from "@/assets/images/email-hover.png";
import AlexaCover from "@/assets/images/alexa-cover.png";
import AlexaHover from "@/assets/images/alexa-hover.png";
import LaptopCover from "@/assets/images/laptop-cover.png";
import LaptopHover from "@/assets/images/laptop-hover.png";
import MobileCover from "@/assets/images/mobile-cover.png";
import MobileHover from "@/assets/images/mobile-hover.png";
import IpadCover from "@/assets/images/ipad-cover.png";
import IpadHover from "@/assets/images/ipad-hover.png";
import ConnectingDotJam from "@/assets/images/connecting-dot-jam.png";
import Connection from "@/assets/images/connection.png";
import TVRepo from "@/assets/images/tv-repo.png";
import RadioRepo from "@/assets/images/radio/repo.png";
import EmailRepo from "@/assets/images/email/repo.png";
import AlexaRepo from "@/assets/images/alexa/repo.png";

import MobileRepo from "@/assets/images/mobile/repo.png";
import LaptopRepo from "@/assets/images/laptop/repo.png";
import IpadRepo from "@/assets/images/ipad/repo.png";

export default defineComponent({
  name: "RuBaaRu",
  components: {},
  setup() {
    const data = reactive({
      projects: [],
    });
    let hideOther = ref(false);
    let tvHover = ref(false);
    let radioHover = ref(false);
    let emailHover = ref(false);
    let alexaHover = ref(false);
    let laptopHover = ref(false);
    let ipadHover = ref(false);
    let mobileHover = ref(false);
    const repository = ref(null);
    const tvRepository = ref(null);
    const radioRepository = ref(null);
    const emailRepository = ref(null);
    const alexaRepository = ref(null);
    const mobileRepository = ref(null);
    const laptopRepository = ref(null);
    const ipadRepository = ref(null);
    let isTVVisible = ref(false);
    let isRadioVisible = ref(false);
    let isEmailVisible = ref(false);
    let isAlexaVisible = ref(false);
    let isMobileVisible = ref(false);
    let isLaptopVisible = ref(false);
    let isIpadVisible = ref(false);
    let mainContainer = ref(null);
    let isMainVisible = ref(true);

    const scrollToRepository = () => {
      isTVVisible.value = false;
      isRadioVisible.value = false;
      isEmailVisible.value = false;
      isAlexaVisible.value = false;
      isLaptopVisible.value = false;
      isMobileVisible.value = false;
      isIpadVisible.value = false;
      isMainVisible.value = true;
      setTimeout(() => {
        repository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToTVRepository = () => {
      isTVVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        tvRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToRadioRepository = () => {
      isRadioVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        radioRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToEmailRepository = () => {
      isEmailVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        emailRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToAlexaRepository = () => {
      isAlexaVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        alexaRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToMobileRepository = () => {
      isMobileVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        mobileRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToLaptopRepository = () => {
      isLaptopVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        laptopRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const scrollToIpadRepository = () => {
      isIpadVisible.value = true;
      isMainVisible.value = false;
      setTimeout(() => {
        ipadRepository.value.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
    const mouseToggle = (param, option) => {
      switch (option) {
        case "tv":
          tvHover.value = param;
          break;
        case "radio":
          radioHover.value = param;
          break;
        case "email":
          emailHover.value = param;
          break;
        case "alexa":
          alexaHover.value = param;
          break;
        case "laptop":
          laptopHover.value = param;
          break;
        case "mobile":
          mobileHover.value = param;
          break;
        case "ipad":
          ipadHover.value = param;
          break;
      }
      hideOther.value = param;
    };
    return {
      data,
      RuBaaRuuHead,
      DownArrow,
      scrollToRepository,
      repository,
      tvRepository,
      radioRepository,
      emailRepository,
      alexaRepository,
      mobileRepository,
      laptopRepository,
      ipadRepository,
      TVCover,
      RepositoryCover,
      RadioCover,
      EmailCover,
      AlexaCover,
      LaptopCover,
      MobileCover,
      IpadCover,
      TVHover,
      tvHover,
      RadioHover,
      radioHover,
      EmailHover,
      emailHover,
      AlexaHover,
      alexaHover,
      MobileHover,
      mobileHover,
      LaptopHover,
      laptopHover,
      IpadHover,
      ipadHover,
      hideOther,
      mouseToggle,
      ConnectingDotJam,
      Connection,
      TVRepo,
      scrollToTVRepository,
      RadioRepo,
      scrollToRadioRepository,
      EmailRepo,
      scrollToEmailRepository,
      AlexaRepo,
      scrollToAlexaRepository,
      MobileRepo,
      scrollToMobileRepository,
      LaptopRepo,
      scrollToLaptopRepository,
      IpadRepo,
      scrollToIpadRepository,
      isTVVisible,
      isRadioVisible,
      isEmailVisible,
      isAlexaVisible,
      isLaptopVisible,
      isMobileVisible,
      isIpadVisible,
      mainContainer,
      isMainVisible,
    };
  },
});
</script>

<style scoped>
.head {
  height: 100vh;
}
.head-image {
  text-align: center;
  padding-top: calc(100vh / 7);
}
.head-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 34px;
  padding-top: 32px;
  /* or 53% */

  color: #ffc701;

  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.3);
}
.head-sub-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 34px;
  padding-top: 30px;
  /* or 142% */

  text-align: center;
  letter-spacing: 0.1em;

  color: #606060;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
}
.down-link-wrapper {
  margin-top: 42px;
  margin-bottom: 50px;
  cursor: pointer !important;
}
.repository-cover {
  position: absolute;
  left: 30px;
  top: 70px;
}
.repository-top {
  padding-top: 30px;
}
.repository {
  text-align: center;
  position: relative;
  margin-left: -50px;
  height: calc(100vh - 70px) !important;
}

.tv-cover {
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
}
.tv-hover {
  position: absolute;
  left: 0px;
  top: 0px;
}
.tv-cover:hover {
  content: url(~@/assets/images/tv-active.png);
}
.radio-hover {
  position: absolute;
  left: 135px;
  top: 240px;
}
.radio-cover {
  position: absolute;
  left: 297px;
  top: 30px;
  cursor: pointer;
}
.radio-cover:hover {
  content: url(~@/assets/images/radio-active.png);
}
.email-hover {
  position: absolute;
  left: 80px;
  top: 273px;
}
.email-cover {
  position: absolute;
  left: 651px;
  top: 10px;
  cursor: pointer;
}
.email-cover:hover {
  content: url(~@/assets/images/email-active.png);
}
.alexa-hover {
  position: absolute;
  left: 325px;
  top: 30px;
  cursor: pointer;
}
.alexa-cover {
  position: absolute;
  left: 330px;
  top: 366px;
  cursor: pointer;
}
.alexa-cover:hover {
  content: url(~@/assets/images/alexa-active.png);
}
.laptop-hover {
  position: absolute;
  left: 82px;
  top: 0px;
  cursor: pointer;
}
.laptop-cover {
  position: absolute;
  left: 0px;
  top: 540px;
  cursor: pointer;
}
.laptop-cover:hover {
  content: url(~@/assets/images/laptop-active.png);
}
.mobile-hover {
  position: absolute;
  left: 0px;
  top: 15px;
  cursor: pointer;
}
.mobile-cover {
  position: absolute;
  left: 1109px;
  top: 350px;
  cursor: pointer;
}
.mobile-cover:hover {
  content: url(~@/assets/images/mobile-active.png);
}
.ipad-hover {
  position: absolute;
  left: 15px;
  top: 0px;
  cursor: pointer;
}
.ipad-cover {
  position: absolute;
  left: 1180px;
  top: 622px;
  cursor: pointer;
}
.ipad-cover:hover {
  content: url(~@/assets/images/ipad-active.png);
}
.animate {
  transition: visibility 0s, opacity 5s linear;
}
.repo-description {
  margin-top: calc(100vh / 15);
}
.repo-description-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */

  text-align: center;

  color: #000000;
}
.repo-description-body {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  /* or 145% */

  text-align: justify;

  color: #000000;
  padding-top: 50px;
}
hr {
  margin-top: calc(100vh / 15);
}
.ConnectingDotJam {
  width: 100%;
}
.connection {
  text-align: center;
}
.repo-description-head-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  color: #000000;
  text-align: center;
}
.margin-top-50 {
  margin-top: calc(100vh / 15);
}
.tv-cinema {
  content: url(~@/assets/images/tv/cinema.png);
}
.tv-cinema:hover {
  content: url(~@/assets/images/tv/cinema-hover.png);
}
.tv-newspaper {
  content: url(~@/assets/images/tv/newspaper.png);
}
.tv-newspaper:hover {
  content: url(~@/assets/images/tv/newspaper-hover.png);
}
.tv-song {
  content: url(~@/assets/images/tv/song.png);
}
.tv-song:hover {
  content: url(~@/assets/images/tv/song-hover.png);
}
.tv-text {
  content: url(~@/assets/images/tv/radio-text.png);
}
.tv-text:hover {
  content: url(~@/assets/images/tv/radio-overlap.png);
}
.radio-song {
  content: url(~@/assets/images/radio/song.png);
}
.radio-song:hover {
  content: url(~@/assets/images/radio/song-hover.png);
}
.radio-news {
  content: url(~@/assets/images/radio/news.png);
}
.radio-news:hover {
  content: url(~@/assets/images/radio/news-hover.png);
}
.radio-text {
  content: url(~@/assets/images/radio/overlap.png);
}
.radio-text:hover {
  content: url(~@/assets/images/radio/text.png);
}
/*** */
.email-texts {
  content: url(~@/assets/images/email/texts.png);
}
.email-texts:hover {
  content: url(~@/assets/images/email/texts-hover.png);
}

.email-letter {
  content: url(~@/assets/images/email/letter.png);
}
.email-letter:hover {
  content: url(~@/assets/images/email/letter-hover.png);
}
.email-text {
  content: url(~@/assets/images/email/overlap.png);
}
.email-text:hover {
  content: url(~@/assets/images/email/text.png);
}
.alexa-light {
  content: url(~@/assets/images/alexa/light.png);
}
.alexa-light:hover {
  content: url(~@/assets/images/alexa/light-hover.png);
}
.alexa-song {
  content: url(~@/assets/images/alexa/song.png);
}
.alexa-song:hover {
  content: url(~@/assets/images/alexa/song-hover.png);
}
.alexa-text {
  content: url(~@/assets/images/alexa/text.png);
}
.alexa-text:hover {
  content: url(~@/assets/images/alexa/overlap.png);
}
.mobile-m1 {
  content: url(~@/assets/images/mobile/m1.png);
}
.mobile-m1:hover {
  content: url(~@/assets/images/mobile/m1-hover.png);
}
.mobile-m2 {
  content: url(~@/assets/images/mobile/m2.png);
}
.mobile-m2:hover {
  content: url(~@/assets/images/mobile/m2-hover.png);
}
.mobile-m3 {
  content: url(~@/assets/images/mobile/m3.png);
}
.mobile-m3:hover {
  content: url(~@/assets/images/mobile/m3-hover.png);
}
.mobile-text {
  content: url(~@/assets/images/mobile/text.png);
}
.mobile-text:hover {
  content: url(~@/assets/images/mobile/overlap.png);
}

.laptop-l1 {
  content: url(~@/assets/images/laptop/l1.png);
}
.laptop-l1:hover {
  content: url(~@/assets/images/laptop/l1-hover.png);
}
.laptop-l2 {
  content: url(~@/assets/images/laptop/l2.png);
}
.laptop-l2:hover {
  content: url(~@/assets/images/laptop/l2-hover.png);
}
.laptop-l3 {
  content: url(~@/assets/images/laptop/l3.png);
}
.laptop-l3:hover {
  content: url(~@/assets/images/laptop/l3-hover.png);
}
.laptop-text {
  content: url(~@/assets/images/laptop/overlap.png);
}
.laptop-text:hover {
  content: url(~@/assets/images/laptop/text.png);
}
.ipad-l1 {
  content: url(~@/assets/images/ipad/l1.png);
}
.ipad-l1:hover {
  content: url(~@/assets/images/ipad/l1-hover.png);
}
.ipad-l2 {
  content: url(~@/assets/images/ipad/l2.png);
}
.ipad-l2:hover {
  content: url(~@/assets/images/ipad/l2-hover.png);
}
.ipad-l3 {
  content: url(~@/assets/images/ipad/l3.png);
}
.ipad-l3:hover {
  content: url(~@/assets/images/ipad/l3-hover.png);
}
.ipad-text {
  content: url(~@/assets/images/ipad/overlap.png);
}
.ipad-text:hover {
  content: url(~@/assets/images/ipad/text.png);
}
.sher {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  line-height: 28px;
  /* or 187% */

  text-align: center;

  color: #000000;
}
button {
  border: 2px solid #ffc701;
  border-radius: 12px;
}
</style>
