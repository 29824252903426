<template>
  <div class="not-found">
    <div class="not-found m-5 quote">
      "Is mod se jaate hain kuchh sust qadam raste kuchh tez qadam raahe
      <br />Aandhi ki tarah udakar ek raah guzarati hai
      <br />Sharamaati hui koi kadmo se utarati hai
      <br />In reshami raaho me ek raah to vo hogi
      <br />Tum tak jo pahuchati hai is mod se jaati hai
      <br />Is mod se jaate hai" - Gulzar
    </div>
    <div class="mt-5">
      <h6>
        Sorry, we can't find that page. But we have exciting details on the home
        page.
      </h6>
    </div>
    <div class="m-2">
      <router-link to="home" class="btn btn-primary">Home</router-link>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageNotFound",
});
</script>

<style scoped>
.not-found {
  text-align: center;
}
</style>
