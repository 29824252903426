<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white heading sticky-top">
    <router-link class="navbar-brand" to="/">
      <div>
        <div>
          <img src="@/assets/images/logo.png" width="36" />
        </div>
      </div>
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/projects">work</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/about">about</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/blog">notion</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/others">etc.</router-link>
        </li>
        <li class="nav-item" v-if="currentUser">
          <a class="nav-link" @click.prevent="logOut">logOut</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { computed, defineComponent } from "vue";
import router from "@/router";
import store from "@/store";
export default defineComponent({
  setup() {
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    const logOut = () => {
      store.dispatch("auth/logout");
      router.push("/tipsy-admin");
    };
    return { currentUser, logOut };
  },
});
</script>

<style lang="scss">
.logo-color {
  color: $primary;
}

.navbar-expand-lg {
  padding-top: 2.5rem;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: right;
}

.nav-link {
  cursor: pointer;
  font-size: 1.25rem;
  color: #363636 !important;
}

.nav-link:hover {
  cursor: pointer;
  color: $primary !important;
  text-decoration: underline;
}

a.router-link-exact-active {
  color: $primary !important;
  text-decoration: underline;
}
</style>
