<template>
  <div class="footer hello-container">
    <div class="title">Get in touch</div>
    <h1 class="hello heading">Let's work together</h1>
    <div>
      <a class="m-3" href="https://dribbble.com/thetipsyartist"
        target="_blank">
        <img :src="dribbleLogo" alt="behance" />
      </a>
      <a class="m-3" href="hhttps://medium.com/@1303mansi"
        target="_blank">
        <img :src="mediumLogo" alt="behance" />
      </a>
      <a class="m-3" href="https://www.instagram.com/the.tipsy.artist/" target="_blank">
        <img :src="instaLogo" alt="insta" />
      </a>
      <a class="m-3" href="mailto:1303mansi@gmail.com" target="_blank">
        <img :src="emailLogo" alt="email" />
      </a>
    </div>
    <div class="copyright">
      <small>&copy; 2022 By the tipsy artist</small>
    </div>
  </div>
</template>

<script>
import instaLogo from "@/assets/images/insta.svg";
import behanceLogo from "@/assets/images/behance.svg";
import dribbleLogo from "@/assets/images/dribble.svg";
import emailLogo from "@/assets/images/email.svg";
import mediumLogo from "@/assets/images/medium.svg";
export default {
  name: "Footer",
  setup() {
    return { instaLogo, behanceLogo, emailLogo, mediumLogo, dribbleLogo };
  },
};
</script>

<style scoped>
.footer {
  text-align: center;
}

.title {
  font-size: 1.125rem;
  font-size: clamp(0.75rem, 1.04vw, 1.125rem) !important;
}

.copyright {
  padding-top: 15px;
}
</style>
