<template>
  <div class>
    <div class="m-5 quote">
      <drop-zone v-model="files"></drop-zone>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import DropZone from "../components/common/media/DropZone";
import imageService from "../services/image.service";
export default defineComponent({
  name: "UploadImage",
  components: { DropZone },
  setup() {
    const files = ref([]);
    const pushedFiles = [];
    const fileProgress = ref({});
    const onUploadProgress = (event) => {
      fileProgress.value = Math.round((100 * event.loaded) / event.total);
    };
    watch(
      () => files,
      (items) => {
        items.value.forEach(async (item) => {
          if (!pushedFiles.some((pFile) => pFile.name === item.name)) {
            await imageService.uploadFiles(item, onUploadProgress);
            pushedFiles.push(item);
          }
        });
      },
      { deep: true }
    );
    return { files };
  },
});
</script>
