<template>
  <div>
    <div class="text-center">
      <router-link to="/blog/create" v-if="currentUser" class="btn btn-primary">Create New</router-link>
      <div v-if="data.posts.length === 0">
        <h2>No post found at the moment</h2>
      </div>
    </div>
    <div class="row">
      <div class="row" v-for="post in data.posts" :key="post._id">
        <div class="col-md-1"></div>
        <div class="col-md-8 d-flex flex-row align-items-center justify-content-center">
          <div class="card-body align-items-center justify-content-center">
            <h5 class="card-title semibold">
              <router-link :to="{ name: 'PostView', params: { id: post._id } }" class="card-title">{{ post.title }}
              </router-link>
            </h5>
            <small class="text-muted">{{ dateTime(post.date_posted) }}</small>
            <p class="card-text">{{ post.description }}</p>
            <small class="text-muted semibold tags">{{ post.tags }}</small>
            <div class="d-flex justify-content-between align-items-center">
              <div class="btn-group" style="margin-bottom: 20px">
                <router-link :to="{ name: 'PostEdit', params: { id: post._id } }"
                  class="btn btn-sm btn-outline-secondary" v-if="currentUser">Edit Project</router-link>
                <button class="btn btn-sm btn-outline-secondary" v-on:click="deleteBlog(post._id)" v-if="currentUser">
                  Delete Project
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 card-body">
          <img :src="post.imageUrl" class="blog-image w-100" />
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import moment from "moment";
import blogService from "../services/blog.service";
import store from "@/store";
export default defineComponent({
  name: "Blog",
  setup() {
    const data = reactive({
      posts: [],
    });
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    const deleteBlog = async (id) => {
      await blogService.deletePost(id);
      data.posts = await blogService.fetchPosts();
    };
    onMounted(async () => {
      data.posts = await blogService.fetchPosts();
    });
    const dateTime = (value) => {
      return moment(value).format("MMM DD, YY");
    };
    return {
      data,
      currentUser,
      deleteBlog,
      dateTime,
    };
  },
});
</script>
<style scoped lang="scss">
.wrapper {
  padding-top: 3rem !important;
}

.card-body {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.blog-image {
  max-width: 198px;
}

.card-title {
  font-size: clamp(1.125rem, 1.963vw, 34px);
  text-decoration: none;
  color: $body-color;
  margin-bottom: 0px;
}

.card-text {
  font-size: clamp(0.75rem, 1.04vw, 18px) !important;
  font-style: italic;
  padding-top: 1rem;
  line-height: clamp(0.8rem, 1.27vw, 22px) !important;
}

.tags {
  color: #c4c4c4 !important;
}
</style>
