<template>
  <div class="row hello-top-container align-items-center">
    <div class="col-12 col-lg-12 col-xl-12">
      <h1 class="heading hello">Hello stranger!</h1>
      <h2 class="heading padding-top-1rem">
        <div>this is Mansi aka the tipsy artist. I am
        <span class="typed-text">{{ data.typedData.typeValue }}</span>
        <span class="blinking-cursor">|</span>
        <span class="cursor" :class="{ typing: data.typedData.typeStatus }"
          >&nbsp;</span>
        </div>
        <!-- <vue-typer text='Hello World! I was registered globally!'></vue-typer> -->
        <!-- <vue-typed-js
          :strings="[
            'product designer',
            'interaction designer',
            'a creative prictioner',
            'a ux researcher',
          ]"
        >
          <h1 class="typing"></h1>
        </vue-typed-js> -->
      </h2>
      <div class="row padding-top-1rem">
        <div class="col-10 col-sm-12 gt-2">
          <p class="detail extralight">
            With my analytical and collaborative approach, I visualize ideas from different perspectives and aim to create meaningful experiences. I turn complex requirements into elegant design solutions with a human-centered approach with a keen eye for aesthetics and functionality.
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="col-12 col-lg-4 col-xl-4">
      <div class="align-items-center" align="center">
        <img src="@/assets/images/mansi.webp" class="w-100 me" />
      </div>
    </div> -->
  </div>
  <div class="row section2">
    <div class="col-12 col-lg-12 col-xl-12">
      <div class="detail1 extralight">I try to</div>
      <div class="detail2"><span class="detail2-design">design</span>, paint, click, illustrate and explore</div>
    </div>
  </div>
  <div class="row section h-container">
    <div class="col-12">
      <router-link class="nav-link" to="/projects">
        <img
          src="https://ik.imagekit.io/shhlxzwhdeyt/project/projects_kJU0uinib.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1652280265885"
          class="w-100"
        />
      </router-link>
    </div>
    <!-- <div class="col-4">
      <div class="col-12">
        <img src="@/assets/images/scoopin3.png" class="w-100" />
      </div>
    </div>
    <div class="col-2">
      <img src="@/assets/images/scoopins2.png" class="w-100" />
    </div>
    <div class="col-3">
      <img src="@/assets/images/scoopin3.png" class="w-100" />
    </div>
    <div class="col-3">
      <img src="@/assets/images/scoopin3.png" class="w-100" />
    </div> -->
  </div>
  <!-- <div class="row section hello-container">
    <div class="col-12">
      <h2>recent works</h2>
      <div class="row">
        <div class="col-12 quote">
          <div>“mai is umeed mai duba ki tu bacha legi,</div>
          <div>ab iske bad mera inteha tu</div>
          <div>kya legi” John Eliya</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row section hello-container project-container">
    <div
      class="col-3 project-card"
      v-for="project in data.projects"
      :key="project._id"
      @click="viewProject(project._id)"
    >
      <div class="row">
        <div class="col-12" align="center">
          <img :src="project.smallImageUrl" />
        </div>
        <div class="project-detail">
          <h3>{{ project.title }}</h3>
          <p>{{ project.smallDescription }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row section hello-container">
    <div class="col-12">
      <h2>recent blog</h2>
      <div class="row">
        <div class="col-12 quote">
          <div>“mai is umeed mai duba ki tu bacha legi,</div>
          <div>ab iske bad mera inteha tu</div>
          <div>kya legi” John Eliya</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row section hello-container">
    <div class="col-6">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
          <img src="@/assets/images/blog.png" />
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">
          <h3 class="mt-2">Tracing Transition</h3>
          <p class="blog-detail mt-4">
            Transition Design asks whether we can develop an approach or
            approaches that can shift the trajectories of the complex systems
            through strategically placed ecologies of intervention in a
            timeframe.
          </p>
          <small class="tag">Design.Fashion.Change</small>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
          <img src="@/assets/images/blog.png" />
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8">
          <h3 class="mt-2">Tracing Transition</h3>
          <p class="blog-detail mt-4">
            Transition Design asks whether we can develop an approach or
            approaches that can shift the trajectories of the complex systems
            through strategically placed ecologies of intervention in a
            timeframe.
          </p>
          <small class="tag">Design.Fashion.Change</small>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import router from "../router";
import projectService from "../services/project.service";

export default defineComponent({
  name: "HomeView",

  setup() {
    const data = reactive({
      projects: {},
      typedData: {
        typeValue: "",
        typeStatus: false,
        displayTextArray: [
          "a product designer",
          "an interaction designer",
          "a creative practitioner",
          "a design researcher",
        ],
        typingSpeed: 50,
        erasingSpeed: 50,
        newTextDelay: 1000,
        displayTextArrayIndex: 0,
        charIndex: 0,
      },
    });

    const viewProject = (id) => {
      router.push("/project/view/" + id);
    };
    onMounted(async () => {
      setTimeout(typeText, data.typedData.newTextDelay + 200);
      data.projects = await projectService.fetchProjectsForHome();
      console.log(data.projects);
    });
    const typeText = () => {
      if (
        data.typedData.charIndex <
        data.typedData.displayTextArray[data.typedData.displayTextArrayIndex]
          .length
      ) {
        if (!data.typedData.typeStatus) data.typedData.typeStatus = true;
        data.typedData.typeValue += data.typedData.displayTextArray[
          data.typedData.displayTextArrayIndex
        ].charAt(data.typedData.charIndex);
        data.typedData.charIndex += 1;
        setTimeout(typeText, data.typedData.typingSpeed);
        console.log(data.typedData.typeValue);
      } else {
        data.typedData.typeStatus = false;
        setTimeout(eraseText, data.typedData.newTextDelay);
      }
    };
    const eraseText = () => {
      if (data.typedData.charIndex > 0) {
        if (!data.typedData.typeStatus) data.typedData.typeStatus = true;
        data.typedData.typeValue = data.typedData.displayTextArray[
          data.typedData.displayTextArrayIndex
        ].substring(0, data.typedData.charIndex - 1);
        data.typedData.charIndex -= 1;
        setTimeout(eraseText, data.typedData.erasingSpeed);
      } else {
        data.typedData.typeStatus = false;
        data.typedData.displayTextArrayIndex += 1;
        if (
          data.typedData.displayTextArrayIndex >=
          data.typedData.displayTextArray.length
        )
          data.typedData.displayTextArrayIndex = 0;
        setTimeout(typeText, data.typedData.typingSpeed + 1000);
      }
    };
    return {
      data,
      viewProject,
      typeText,
      eraseText,
    };
  },
});
</script>

<style lang="scss" scoped>
.padding-top-1rem {
  padding-top: 1rem;
}
.heading {
  font-size: clamp(1.625rem, 2.1vw, 40px) !important;
}
.hello {
  font-size: clamp(2rem, 3.24vw, 56px) !important;
}
.detail {
  font-size: clamp(1rem, 1.5vw, 26px) !important;
  line-height: 150%;
}
.detail1 {
  font-family: 'Courier Prime', monospace, sans-serif !important;
  font-size: clamp(1rem, 1.5vw, 26px) !important;
  line-height: 123%;
}
.detail2 {
  font-family: 'Courier Prime', monospace, sans-serif !important;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: #BEBEBE;
}
.detail2-design {
  color: #ffc700;
}

.project-detail {
  padding-top: 60px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.blog-detail {
  font-style: italic;
  font-weight: 300;
}

.tag {
  color: $light-grey;
}

.project-container {
  margin-top: -1rem !important;
}

.project-card {
  padding: 1rem !important;
  border-radius: 15px;
}

.project-card:hover {
  cursor: pointer;
  background-color: $primary;
}

img.me {
  max-width: 580px;
}

.underline {
  background: linear-gradient($primary, $primary);
  background-size: auto 0.225em;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 3px;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1 {
  font-size: 6rem;
  font-weight: normal;
  span.typed-text {
    color: #d2b94b;
  }
}
.section {
  padding-top: clamp(2rem, 3vw, 32px) !important;
}
.section2 {
  padding-top: clamp(8rem, 12vw, 128px) !important;
}

// Cursor blinking CSS Starts...
.blinking-cursor {
  font-size: 1.5rem;
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
</style>
