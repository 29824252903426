<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 style="margin-top: 30px"></h4>
      <h1 class="page-heading heading">{{ data.project.title }}</h1>
      <h4 class="tagline light">{{ data.project.smallDescription }}</h4>
      <p v-html="data.project.body"></p>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import projectService from "../../services/project.service";
import store from "@/store";
export default defineComponent({
  name: "ProjectView",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      project: {},
    });
    const currentUser = computed(() => {
      return store.state.auth.user;
    });
    onMounted(async () => {
      data.project = await projectService.getProject(route.params.id);
      if (data.project.href) {
        router.push(data.project.href);
      }
      // const matcher = /<img/g;
      const imgTags = data.project.body.match(
        /<img [^>]*width="[^"]*"[^>]*>/gm
      );
      const tmp = document.createElement("div");
      if (null !== imgTags) {
        imgTags.map((x) => {
          tmp.innerHTML = x;
          const image = tmp.querySelector("img");
          const width = image.getAttribute("width");
          image.setAttribute("style", "max-width:" + width + "px");
          image.className = "responsive";
          console.log(x, tmp.innerHTML, "--");
          data.project.body = data.project.body.replace(x, tmp.innerHTML);
          // data.project.body = data.project.body.replaceAll(x, tmp.innerHTML);
        });
      }
    });
    return {
      data,
      currentUser,
    };
  },
});

let idleTimer = null;
let idleState = false;

function showFoo(time) {
  clearTimeout(idleTimer);
  if (idleState == true) {
    document.getElementById("hideMe").classList.remove("inactive");
  }
  idleState = false;
  idleTimer = setTimeout(function () {
    if (!document.getElementById("hideMe")) return;
    document.getElementById("hideMe").classList.add("inactive");
    idleState = true;
  }, time);
}

showFoo(2000);

document.addEventListener("mousemove", function () {
  showFoo(2000);
});

setTimeout(() => {
  const elems = document.querySelectorAll("ul#hideMe a");
  for (let i = 0; i < elems.length; i++) {
    elems[i].addEventListener("click", markCurrent(this));
  }
}, 3000);

function markCurrent(elem) {
  if (!elem) return;
  var elems = document.querySelectorAll("ul#hideMe a");

  [].forEach.call(elems, function (el) {
    el.classList.remove("active");
  });
  elem.classList.add("active");
}
</script>

<style scoped>
.tagline {
  font-size: 1.25rem;
  margin-bottom: 3rem;
}

img {
  width: 100% !important;
  height: auto;
}
</style>
