<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 style="margin-top: 30px"></h4>
      <h1 class="page-heading heading">Material Exploration</h1>
      <h4 class="tagline light">links</h4>
    </div>
  </div>
  <div class="photo-row" style="margin-top: 50px">
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index - 1].src"
        :title="images[index - 1].title" :desc="images[index - 1].desc" :series="images[index - 1].series"
        v-for="index in 9" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 9 - 1].src"
        :title="images[index + 9 - 1].title" :desc="images[index + 9 - 1].desc" :series="images[index + 9 - 1].series"
        v-for="index in 8" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 17 - 1].src"
        :title="images[index + 17 - 1].title" :desc="images[index + 17 - 1].desc"
        :series="images[index + 17 - 1].series" v-for="index in 9" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 26 - 1].src"
        :title="images[index + 26 - 1].title" :desc="images[index + 26 - 1].desc"
        :series="images[index + 26 - 1].series" v-for="index in 9" :key="index" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
// import ExpandableImage from "vue-expandable-image";
import ExpandableImage from "../components/common/ExpandableImage.vue";
export default defineComponent({
  components: { ExpandableImage },
  setup() {
    const images = ref([]);
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.1?ik-sdk-version=javascript-1.4.3&updatedAt=1652896852767",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.2?ik-sdk-version=javascript-1.4.3&updatedAt=1652896852889",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.3?ik-sdk-version=javascript-1.4.3&updatedAt=1652896917939",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.4?ik-sdk-version=javascript-1.4.3&updatedAt=1652896853307",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.5?ik-sdk-version=javascript-1.4.3&updatedAt=1652896853624",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.6?ik-sdk-version=javascript-1.4.3&updatedAt=1652896854145",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.7?ik-sdk-version=javascript-1.4.3&updatedAt=1652896854763",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.8?ik-sdk-version=javascript-1.4.3&updatedAt=1652896855161",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R1.9?ik-sdk-version=javascript-1.4.3&updatedAt=1652896855090",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.1?ik-sdk-version=javascript-1.4.3&updatedAt=1652893442320",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.3?ik-sdk-version=javascript-1.4.3&updatedAt=1652894028298",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.2?ik-sdk-version=javascript-1.4.3&updatedAt=1652893952602",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.4?ik-sdk-version=javascript-1.4.3&updatedAt=1652902417230",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.5?ik-sdk-version=javascript-1.4.3&updatedAt=1652893952579",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.6_w58iqHvll?ik-sdk-version=javascript-1.4.3&updatedAt=1652893953981",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.7_dqgH_Gq32?ik-sdk-version=javascript-1.4.3&updatedAt=1652893953870",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R2.8_p13JGDaRB?ik-sdk-version=javascript-1.4.3&updatedAt=1652893954465",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.1?ik-sdk-version=javascript-1.4.3&updatedAt=1652894687013",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.2?ik-sdk-version=javascript-1.4.3&updatedAt=1652894687832",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.3?ik-sdk-version=javascript-1.4.3&updatedAt=1652894688191",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.4?ik-sdk-version=javascript-1.4.3&updatedAt=1652894688452",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.5_Ib1pJz8CJ?ik-sdk-version=javascript-1.4.3&updatedAt=1652894688786",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.6?ik-sdk-version=javascript-1.4.3&updatedAt=1652896395538",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.7?ik-sdk-version=javascript-1.4.3&updatedAt=1652895312796",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.8?ik-sdk-version=javascript-1.4.3&updatedAt=1652895312821",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R3.9?ik-sdk-version=javascript-1.4.3&updatedAt=1652895313168",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.1?ik-sdk-version=javascript-1.4.3&updatedAt=1652895860409",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.2?ik-sdk-version=javascript-1.4.3&updatedAt=1652895860427",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.3?ik-sdk-version=javascript-1.4.3&updatedAt=1652895861103",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/r4.4?ik-sdk-version=javascript-1.4.3&updatedAt=1652896713409",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.5?ik-sdk-version=javascript-1.4.3&updatedAt=1652895861649",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.6?ik-sdk-version=javascript-1.4.3&updatedAt=1652896380868",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.7?ik-sdk-version=javascript-1.4.3&updatedAt=1652895862361",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.8?ik-sdk-version=javascript-1.4.3&updatedAt=1652895862880",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/ME/R4.9?ik-sdk-version=javascript-1.4.3&updatedAt=1652895862903",
    });
    return {
      images,
    };
  },
});
</script>

<style lang="scss" scoped>
.photo-row {
  display: flex;
  flex-wrap: wrap;
}

.photo-col {
  flex: 25%;
  max-width: 25%;
}

.image {
  vertical-align: middle;
  width: 100%;
  padding: 10px;
}
</style>
