<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 style="margin-top: 30px"></h4>
      <h1 class="page-heading heading">Photography</h1>
      <h4 class="tagline light">moments</h4>
    </div>
  </div>
  <div class="photo-row" style="margin-top: 50px">
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index - 1].src"
        :title="images[index - 1].title" :desc="images[index - 1].desc" :series="images[index - 1].series"
        v-for="index in 7" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 7 - 1].src"
        :title="images[index + 7 - 1].title" :desc="images[index + 7 - 1].desc" :series="images[index + 7 - 1].series"
        v-for="index in 7" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 14 - 1].src"
        :title="images[index + 14 - 1].title" :desc="images[index + 14 - 1].desc"
        :series="images[index + 14 - 1].series" v-for="index in 7" :key="index" />
    </div>
    <div class="photo-col">
      <ExpandableImage class="image" :closeOnBackgroundClick="true" :src="images[index + 21 - 1].src"
        :title="images[index + 21 - 1].title" :desc="images[index + 21 - 1].desc"
        :series="images[index + 21 - 1].series" v-for="index in 7" :key="index" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
// import ExpandableImage from "vue-expandable-image";
import ExpandableImage from "../components/common/ExpandableImage.vue";
export default defineComponent({
  components: { ExpandableImage },
  setup() {
    const images = ref([]);
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.1_new?ik-sdk-version=javascript-1.4.3&updatedAt=1652988635674",
      title: "the bird",
      desc: "series: stills",
      series: "1/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.2?ik-sdk-version=javascript-1.4.3&updatedAt=1652713735708",
      title: "garnishing",
      desc: "series: majuli",
      series: "1/4",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.3?ik-sdk-version=javascript-1.4.3&updatedAt=1652714223738",
      title: "fragrance",
      desc: "series: kodai",
      series: "2/5",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.4?ik-sdk-version=javascript-1.4.3&updatedAt=1652714085579",
      title: "pyala",
      desc: "series: kodai",
      series: "3/5",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.5?ik-sdk-version=javascript-1.4.3&updatedAt=1652714102829",
      title: "smokey bhel",
      desc: "series: kodai",
      series: "4/5",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.6?ik-sdk-version=javascript-1.4.3&updatedAt=1652714137462",
      title: "glimpse",
      desc: "series: moon",
      series: "2/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R1.7?ik-sdk-version=javascript-1.4.3&updatedAt=1652714150362",
      title: "azzure",
      desc: "series: bengaluru",
      series: "3/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.1_ADI5PnbJg?ik-sdk-version=javascript-1.4.3&updatedAt=1652714621938",
      title: "prayag",
      desc: "series: my city",
      series: "1/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.2_2aB1qV3Mm?ik-sdk-version=javascript-1.4.3&updatedAt=1652714622296",
      title: "sparkles",
      desc: "series: new campus",
      series: "1/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.3_8mur9yfg6?ik-sdk-version=javascript-1.4.3&updatedAt=1652714834921",
      title: "little things",
      desc: "series: kodai",
      series: "1/5",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.4?ik-sdk-version=javascript-1.4.3&updatedAt=1652714201559",
      title: "patthar",
      desc: "series: phrazer town",
      series: "1/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.5_kGmLBTuVM?ik-sdk-version=javascript-1.4.3&updatedAt=1652714622942",
      title: "peepout",
      desc: "series: majuli",
      series: "3/4",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.6_W8XqbVyL-?ik-sdk-version=javascript-1.4.3&updatedAt=1652714623185",
      title: "cotton candies",
      desc: "series: majuli",
      series: "4/4",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R2.7_ApGe-xlp9?ik-sdk-version=javascript-1.4.3&updatedAt=1652714624081",
      title: "still crowd",
      desc: "series: phrazer town",
      series: "2/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.1_GNCrQAvTfZ?ik-sdk-version=javascript-1.4.3&updatedAt=1652723396263",
      title: "cracks in air",
      desc: "series: sundari",
      series: "1/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.2_m2eWWkbcX?ik-sdk-version=javascript-1.4.3&updatedAt=1652723412035",
      title: "hoodie",
      desc: "series: bengaluru",
      series: "1/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.3_ZTwub2RI_?ik-sdk-version=javascript-1.4.3&updatedAt=1652716100535",
      title: "pyala",
      desc: "series: bengaluru",
      series: "2/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.4_6CLHXDH0v?ik-sdk-version=javascript-1.4.3&updatedAt=1652716099374",
      title: "smiles",
      desc: "series: sundari",
      series: "3/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.5_N8LiYYev0?ik-sdk-version=javascript-1.4.3&updatedAt=1652716100485",
      title: "allahabad",
      desc: "series: my city",
      series: "2/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.6_3ZIiIH5ZnU?ik-sdk-version=javascript-1.4.3&updatedAt=1652716100654",
      title: "white hats",
      desc: "series: new campus",
      series: "2/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R3.7_47qE_T7Q6?ik-sdk-version=javascript-1.4.3&updatedAt=1652716100758",
      title: "greenland ",
      desc: "series: stills",
      series: "3/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R4.1_FEfMFdYlT?ik-sdk-version=javascript-1.4.3&updatedAt=1652723286454",
      title: "blue fly",
      desc: "series: stills",
      series: "2/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R4.2_Yu6XNVQ-f?ik-sdk-version=javascript-1.4.3&updatedAt=1652723958439",
      title: "blue light",
      desc: "series: moon",
      series: "1/2",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R4.3_Rbka1VcUj?ik-sdk-version=javascript-1.4.3&updatedAt=1652723959483",
      title: "fairy working",
      desc: "series: sundari",
      series: "2/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R4.4_0Irla9c3T?ik-sdk-version=javascript-1.4.3&updatedAt=1652723960030",
      title: "levitate",
      desc: "series: majuli",
      series: "2/4",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R4.5_EzFkP5fCy?ik-sdk-version=javascript-1.4.3&updatedAt=1652723961128",
      title: "sparkling sun",
      desc: "series: majuli",
      series: "2/3",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/R4.6_KkKJpwjGc?ik-sdk-version=javascript-1.4.3&updatedAt=1652723962229",
      title: "windy",
      desc: "series: kodai",
      series: "4/4",
    });
    images.value.push({
      src: "https://ik.imagekit.io/shhlxzwhdeyt/photos/newwwwwwwwwww?ik-sdk-version=javascript-1.4.3&updatedAt=1652989093353",
      title: "prayagraj",
      desc: "series: my city",
      series: "3/3",
    });
    return {
      images,
    };
  },
});
</script>

<style lang="scss" scoped>
.photo-row {
  display: flex;
  flex-wrap: wrap;
}

.photo-col {
  flex: 25%;
  max-width: 25%;
}

.image {
  vertical-align: middle;
  width: 100%;
  padding: 10px;
}
</style>
