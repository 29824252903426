<template>
  <div>
    <div class="col-md-12 form-wrapper">
      <h2>Create Project</h2>
      <form id="create-post-form" @submit.prevent="createProject">
        <div class="form-group col-md-12 my-3">
          <label for="title">Title</label>
          <input type="text" id="title" v-model="title" name="title" class="form-control" placeholder="Enter title" />
        </div>
        <div class="form-group col-md-12 my-3">
          <label for="description">Small description</label>
          <input type="text" id="description" v-model="smallDescription" name="description" class="form-control"
            placeholder="Enter Description" />
        </div>
        <div class="form-group col-md-12 my-3">
          <label for="description">Description</label>
          <input type="text" id="description" v-model="description" name="description" class="form-control"
            placeholder="Enter Description" />
        </div>
        <div class="form-group col-md-12 my-3">
          <label for="body">Write Content</label>
          <editor v-model="body" api-key="iyxpc1oy43cg2fxf6dha0hm3mt6lj4y0h2zduriecmw0d702" :init="{
            height: 500,
            images_upload_url: `${server.baseURL}image/tiny-upload`,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            menubar: 'file edit view insert format tools table help',
            fontsize_formats:
              '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 38pt 40pt 42pt 44pt 46pt 48pt 50pt 52pt 54pt 56pt 58pt 60pt 62pt 64pt 66pt 68pt 70pt 72pt 96pt',
            font_formats:
              'Open Sans=open sans; Roboto=roboto; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; Typewriter=typewriter',
            toolbar:
              'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          }" />
        </div>
        <div class="form-group col-md-12 my-3">
          <label>Small Image </label>
          <a class="btn btn-outline-primary btn-sm m-2" @click="showModal('small')">Select Existing Image</a>
          <a class="btn btn-outline-primary btn-sm m-2" data-bs-toggle="modal" data-bs-target="#uploadModal">Upload
            new</a>
          <input type="hidden" v-model="smallImage" class="form-control" placeholder="Enter url" />
        </div>
        <img :src="smallImageThumbUrl" class="img-thumbnail" />
        <div class="form-group col-md-12 my-3">
          <label>Big Image </label>
          <a class="btn btn-outline-primary btn-sm m-2" @click="showModal('big')">Select Existing Image</a>
          <a class="btn btn-outline-primary btn-sm m-2" data-bs-toggle="modal" data-bs-target="#uploadModal">Upload
            new</a>
          <input type="hidden" v-model="bigImage" class="form-control" placeholder="Enter url" />
        </div>
        <img :src="bigImageThumbUrl" class="img-thumbnail" />
        <div class="form-group col-md-12">
          <label for="title">Tags</label>
          <input type="text" id="tags" v-model="tags" name="tags" class="form-control" placeholder="Enter tags" />
        </div>
        <!-- <div class="form-check form-switch my-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            v-model="isVisibleOnHome"
            value="test"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"
            >Show on home page</label
          >
        </div> -->
        <div class="form-group col-12 pull-right my-2">
          <button class="btn btn-primary" type="submit">Create Project</button>
        </div>
      </form>
    </div>
  </div>
  <div class="modal fade" id="uploadModal" ref="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <upload-image />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="imageViewerModal" ref="imageViewerModal" tabindex="-1"
    aria-labelledby="imageViewerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="imageViewerModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <image-viewer @imageSelected="imageSelected" v-if="isSmall || isBig" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Editor from "@tinymce/tinymce-vue";
import projectService from "@/services/project.service";
import "bootstrap/dist/js/bootstrap.min.js";
import router from "@/router";
import UploadImage from "../../components/common/UploadImage.vue";
import ImageViewer from "../common/media/ImageViewer.vue";
import { Modal } from "bootstrap";
import { server } from "@/utils/helper";

export default defineComponent({
  name: "CreateProject",
  components: {
    editor: Editor,
    UploadImage,
    ImageViewer,
  },
  setup() {
    const title = ref("");
    const tags = ref("");
    const smallDescription = ref("");
    const description = ref("");
    const body = ref("");
    const smallImageUrl = ref("");
    const bigImageUrl = ref("");
    const smallImageThumbUrl = ref("");
    const bigImageThumbUrl = ref("");
    const isVisibleOnHome = ref(false);
    const isSmall = ref(false);
    const isBig = ref(false);
    let myModal = null;
    const showModal = (type) => {
      console.log(type, "----");
      if (type === "small") isSmall.value = true;
      if (type === "big") isBig.value = true;
      myModal = new Modal(document.getElementById("imageViewerModal"), {});
      myModal.show();
    };

    const hideModal = () => {
      console.log("hide");
      isSmall.value = false;
      isBig.value = false;
      myModal.hide();
    };
    const createProject = async () => {
      let projectData = {
        title: title.value,
        smallDescription: smallDescription.value,
        description: description.value,
        body: body.value,
        smallImageUrl: smallImageUrl.value,
        bigImageUrl: bigImageUrl.value,
        isVisibleOnHome: isVisibleOnHome.value,
        tags: tags.value,
      };
      await projectService.createProject(projectData);
      router.push({ name: "project" });
    };
    const imageSelected = (data) => {
      if (isSmall.value) {
        smallImageThumbUrl.value =
          server.baseURL + "public/uploads/100X100/" + data.filename;
        smallImageUrl.value =
          server.baseURL + "public/uploads/" + data.filename;
      }
      if (isBig.value) {
        bigImageThumbUrl.value =
          server.baseURL + "public/uploads/100X100/" + data.filename;
        bigImageUrl.value = server.baseURL + "public/uploads/" + data.filename;
      }
      console.log(data, "here");
      hideModal();
    };
    return {
      title,
      tags,
      smallDescription,
      description,
      body,
      smallImageUrl,
      bigImageUrl,
      smallImageThumbUrl,
      bigImageThumbUrl,
      isVisibleOnHome,
      createProject,
      imageSelected,
      showModal,
      isSmall,
      isBig,
      server,
    };
  },
});
</script>
