<template>
  <div>
    <div class="col-md-12 form-wrapper">
      <h2>Create Post</h2>
      <form id="create-post-form" @submit.prevent="createPost">
        <div class="form-group col-md-12">
          <label for="title">Title</label>
          <input type="text" id="title" v-model="title" name="title" class="form-control" placeholder="Enter title" />
        </div>
        <div class="form-group col-md-12">
          <label for="description">Description</label>
          <input type="text" id="description" v-model="description" name="description" class="form-control"
            placeholder="Enter Description" />
        </div>
        <div class="form-group col-md-12">
          <label for="body">Write Content</label>
          <editor v-model="body" api-key="iyxpc1oy43cg2fxf6dha0hm3mt6lj4y0h2zduriecmw0d702" :init="{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link image code',
          }" />
        </div>
        <div class="form-group col-md-12 my-3">
          <label>Small Image</label>
          <a class="btn btn-outline-primary btn-sm m-2" @click="showModal('small')">Select Existing Image</a>
          <a class="btn btn-outline-primary btn-sm m-2" data-bs-toggle="modal" data-bs-target="#uploadModal">Upload
            new</a>
          <input type="hidden" v-model="smallImage" class="form-control" placeholder="Enter url" />
        </div>
        <img :src="smallImageThumbUrl" class="img-thumbnail" />
        <div class="form-group col-md-12">
          <label for="title">Tags</label>
          <input type="text" id="tags" v-model="tags" name="tags" class="form-control" placeholder="Enter tags" />
        </div>
        <div class="form-group col-md-4 pull-right">
          <button class="btn btn-success" type="submit">Create Post</button>
        </div>
      </form>
    </div>
  </div>
  <div class="modal fade" id="uploadModal" ref="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <upload-image />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="imageViewerModal" ref="imageViewerModal" tabindex="-1"
    aria-labelledby="imageViewerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="imageViewerModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <image-viewer @imageSelected="imageSelected" v-if="isSmall || isBig" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Editor from "@tinymce/tinymce-vue";
import blogService from "@/services/blog.service";
import router from "@/router";
import UploadImage from "../../components/common/UploadImage.vue";
import ImageViewer from "../common/media/ImageViewer.vue";
import { Modal } from "bootstrap";
import { server } from "@/utils/helper";

export default defineComponent({
  name: "CreatePost",
  components: {
    editor: Editor,
    UploadImage,
    ImageViewer,
  },
  setup() {
    const title = ref("");
    const tags = ref("");
    const description = ref("");
    const body = ref("Test");
    const smallImageUrl = ref("");
    const bigImageUrl = ref("");
    const smallImageThumbUrl = ref("");
    const bigImageThumbUrl = ref("");
    const isVisibleOnHome = ref(false);
    const isSmall = ref(false);
    const isBig = ref(false);

    let myModal = null;
    const showModal = (type) => {
      console.log(type, "----");
      if (type === "small") isSmall.value = true;
      if (type === "big") isBig.value = true;
      myModal = new Modal(document.getElementById("imageViewerModal"), {});
      myModal.show();
    };

    const hideModal = () => {
      console.log("hide");
      isSmall.value = false;
      isBig.value = false;
      myModal.hide();
    };

    const createPost = async () => {
      let postData = {
        title: title.value,
        description: description.value,
        body: body.value,
        imageUrl: smallImageUrl.value,
        tags: tags.value,
      };
      await blogService.createPost(postData);
      router.push({ name: "blog" });
    };
    const imageSelected = (data) => {
      if (isSmall.value) {
        smallImageThumbUrl.value =
          server.baseURL + "public/uploads/100X100/" + data.filename;
        smallImageUrl.value =
          server.baseURL + "public/uploads/" + data.filename;
      }
      if (isBig.value) {
        bigImageThumbUrl.value =
          server.baseURL + "public/uploads/100X100/" + data.filename;
        bigImageUrl.value = server.baseURL + "public/uploads/" + data.filename;
      }
      console.log(data, "here");
      hideModal();
    };
    return {
      title,
      tags,
      description,
      body,
      createPost,
      smallImageUrl,
      bigImageUrl,
      smallImageThumbUrl,
      bigImageThumbUrl,
      isVisibleOnHome,
      imageSelected,
      showModal,
      isSmall,
      isBig,
      server,
    };
  },
});
</script>
